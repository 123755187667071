import { host } from "api_client/client";

class InternalApiClient {
  async postTransactionSession({
    transactionId,
    redirectUrl,
    tokenizePaymentMethod,
    countryCode,
  }) {
    const response = await fetch(`${host}transaction-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        transaction_id: transactionId,
        redirect_url: redirectUrl,
        tokenize_payment_method: tokenizePaymentMethod,
        country_code: countryCode.toUpperCase(),
      }),
    });
    return await response.json();
  }
}

export default InternalApiClient;
