import React, { Fragment, useEffect, useState } from "react";
import OnboardingProgressBar from "../progress-bars/OnboardingProgressBar";
import LogoWithText from "../../assets/img/LogoWithText.png";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router";
import PropTypes from "prop-types";
import {
  appName,
  nextOnboardingPage,
  getKYCFlow,
  getRefreshText,
} from "../../modules/Helpers";
import { withApiValue } from "../../modules/WithApiValue";
import GenericBanner from "../banner/GenericBanner";

const config = {
  isOwner: true,
  kyc: { updateRequired: true, resubmitted: true },
  company: {
    companyType: true,
    kyc: {
      updateRequired: true,
      resubmitted: true,
      refreshFields: true,
    },
  },
};

function OnboardingNavbar(props) {
  const [updateStatus, setUpdateStatus] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();
  const history = useHistory();
  const isFirstPage =
    props.kycFlow.indexOf(location.pathname.split("/").pop()) === 0;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setTimeout(function () {
      setDisabled(false);
    }, 500);
  }, [location.pathname]);

  useEffect(() => {
    if (
      props.kyc.updateRequired ||
      props.company.kyc.updateRequired ||
      props.kyc.resubmitted ||
      props.company.kyc.resubmitted
    ) {
      setUpdateStatus(true);
    }
  }, [props.company, props.kyc]);

  useEffect(() => {
    props.setKYCFlow(getKYCFlow(props, updateStatus));
  }, [props.isOwner, updateStatus, props.company]);

  function skip() {
    const currentOnboardingPage = location.pathname.split("/").pop();
    history.push(
      "/onboarding/" + nextOnboardingPage(currentOnboardingPage, props.kycFlow)
    );
  }

  function back() {
    const currentOnboardingPage = location.pathname.split("/").pop();
    const lastPageIndex = props.kycFlow.indexOf(currentOnboardingPage) - 1;
    if (lastPageIndex >= 0) {
      history.push("/onboarding/" + props.kycFlow[lastPageIndex]);
    } else {
      setTimeout(function () {
        setDisabled(false);
      }, 500);
    }
  }

  return (
    <Fragment>
      <OnboardingProgressBar />
      <div className="d-flex justify-content-between">
        <div
          className={`${
            location.pathname === "/onboarding/concierge" && "invisible d-none"
          }`}
        >
          <Button
            type="button"
            className={`btn btn-link font-weight-600 btn-primary d-flex mt-2 ${
              isFirstPage && "invisible"
            }`}
            onClick={() => {
              setDisabled(true);
              back();
            }}
            color={"links"}
            disabled={disabled}
          >
            <FontAwesomeIcon
              style={{ marginRight: "8px", fontSize: "18px" }}
              icon={faAngleLeft}
            />
            Back
          </Button>
        </div>

        <div
          className={`${
            location.pathname !== "/onboarding/concierge" && "invisible d-none"
          }`}
        >
          <Button
            type="button"
            className="btn btn-link font-weight-600 btn-primary d-flex mt-2"
            onClick={() => {
              setDisabled(true);
              history.push("/admin/payments");
            }}
            color={"primary"}
            disabled={disabled}
          >
            <FontAwesomeIcon
              style={{ marginRight: "8px", fontSize: "18px" }}
              icon={faAngleLeft}
            />
            Dashboard
          </Button>
        </div>
        <div className="pt-3" style={{ textAlign: "center" }}>
          <img
            src={LogoWithText}
            alt={`${appName} Logo`}
            style={{ maxWidth: "150px" }}
          />
        </div>
        <div
          className={`${
            (location.pathname === "/onboarding/complete" || !updateStatus) &&
            "invisible"
          }`}
        >
          <Button
            type="button"
            className="btn btn-link font-weight-600 btn-primary d-flex mt-2"
            onClick={() => {
              setDisabled(true);
              skip();
            }}
            color={"links"}
            disabled={disabled}
          >
            {location.pathname === "/onboarding/concierge"
              ? "I got this"
              : "Skip"}
            <FontAwesomeIcon
              style={{ marginLeft: "8px", fontSize: "18px" }}
              icon={faAngleRight}
            />
          </Button>
        </div>
      </div>
      {updateStatus &&
      width >= 1290 &&
      props.isOwner &&
      props.company.kyc.refreshFields &&
      location.pathname !== "/onboarding/complete" ? (
        <div
          className={`announcement-banner shadow-sm d-flex justify-content-start ml-4 mt-5 position-fixed w-25 
                      alert alert-white alert-dismissible fade show`}
          role="alert"
        >
          <div>
            {getRefreshText(
              props.company.kyc.refreshFields,
              location.pathname.split("/").pop()
            )
              .split("\n")
              .map((line, index) => (
                <p key={index}>{line}</p>
              ))}
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}

export default withApiValue(OnboardingNavbar, config, () => null);

OnboardingNavbar.propTypes = {
  isOwner: PropTypes.bool,
  company: PropTypes.object,
  kyc: PropTypes.object,
  kycFlow: PropTypes.array,
  setKYCFlow: PropTypes.func,
};
