import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, Modal, ModalBody, Row } from "reactstrap";
import useForm from "../../hooks/UseForms";
import { CSVLink } from "react-csv";
import useGQL from "../../api_client/UseGQL";
import { useToasts } from "react-toast-notifications";
import { trackEvent } from "../../modules/analytics";
import { MAX_CSV_EXPORT_PAGE_SIZE } from "modules/csv";

function CsvExportModal(props) {
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = useForm(submit);
  const [exportData, setExportData] = useState([]);
  const [download, setDownload] = useState(false);
  const csvLinkRef = useRef(null);
  let gqlHooks = useGQL();
  const { addToast } = useToasts();

  async function submit() {
    setLoading(true);
    try {
      const dataCount = await props.getExportDataCount();

      if (!Number.isInteger(dataCount)) {
        addToast("An error occured while fetching export data count.", {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }

      if (dataCount >= MAX_CSV_EXPORT_PAGE_SIZE) {
        addToast(
          `The CSV export size exceeds the maximum limit of ${MAX_CSV_EXPORT_PAGE_SIZE} rows. Please refine your filters to reduce the export size.`,
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        return;
      }

      const data = await props.getExportData();

      setExportData(data);
      setDownload(true);
      csvLinkRef.current.link.click();

      addToast("File downloaded", {
        appearance: "success",
        autoDismiss: true,
      });

      if (props.eventName) {
        trackEvent(props.eventName, {}, gqlHooks);
      }
    } catch (error) {
      // todo: log error
      addToast("An error occurred while exporting data to CSV", {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
      setExportData([]);
      setDownload(false);
      props.toggle();
    }
  }

  return (
    <Modal backdrop fade centered isOpen={props.isOpen} toggle={props.toggle}>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Button close onClick={props.toggle} />
          </Col>
        </Row>
        <h4 className="text-center">{props.modalTitle}</h4>
        <p className="text-center text-muted">{props.modalDescription}</p>
        <Form onSubmit={handleSubmit}>
          <Button block color="primary" type="submit" disabled={loading}>
            Download
          </Button>
          {download && (
            <CSVLink
              data={exportData}
              headers={props.headers}
              filename={props.filename}
              className="hidden"
              ref={csvLinkRef}
              target="_blank"
            />
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default CsvExportModal;

CsvExportModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  getExportData: PropTypes.func.isRequired,
  getExportDataCount: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalDescription: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  eventName: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.object),
};
