import {
  AdyenComponentResultStatus,
  AdyenCardComponentFieldType,
} from "modules/adyenConfig";
import { AdyenCheckout, Card } from "@adyen/adyen-web";

class AdyenPaymentSessionController {
  constructor(
    internalApiClient,
    internalApiAdyenClient,
    cardBrandRepresentationForAdyenCode,
    setCardDetails
  ) {
    this._internalApiClient = internalApiClient;
    this._internalApiAdyenClient = internalApiAdyenClient;
    this._customerCreditCardIdPromise = new Promise((resolve) => {
      this._resolveCustomerCreditCardId = resolve;
    });
    this._cardBrandRepresentationForAdyenCode =
      cardBrandRepresentationForAdyenCode;
    this._setCardDetails = setCardDetails;
  }

  _setComponent(component) {
    this._component = component;
  }

  async _getAdyenSession(transactionSessionData) {
    const sessionJson = await this._internalApiClient.postTransactionSession(
      transactionSessionData
    );
    const sessionUrl = new URL(sessionJson.url);
    const sessionDataString = sessionUrl.searchParams.get("sessionData");
    const adyenSession = await this._internalApiAdyenClient.postAdyenSession(
      sessionDataString
    );
    return {
      sessionId: adyenSession.id,
      sessionData: adyenSession.session_data,
    };
  }

  async _createSessionConfigurations({
    transactionSessionData,
    checkoutConfigurationData,
    cardConfigurationData,
  }) {
    const SHOW_PAY_BUTTON_BOOLEAN = false;
    const { sessionId, sessionData } = await this._getAdyenSession(
      transactionSessionData
    );

    const checkoutConfiguration = {
      ...checkoutConfigurationData,
      session: { id: sessionId, sessionData: sessionData },
      onPaymentCompleted: this._getOnPaymentSuccessCallback(
        transactionSessionData.transactionId,
        sessionId
      ),
      onPaymentFailed: this._getOnPaymentFailedCallback(),
      showPayButton: SHOW_PAY_BUTTON_BOOLEAN,
    };

    const cardConfiguration = {
      ...cardConfigurationData,
      onFieldValid: this._getOnFieldValidCallback(),
      onBrand: this._getOnBrandCallback(),
    };

    return { checkoutConfiguration, cardConfiguration };
  }
  async _mountAdyenComponent(
    checkoutConfiguration,
    cardConfiguration,
    componentRef
  ) {
    const checkout = await AdyenCheckout(checkoutConfiguration);

    const card = new Card(checkout, cardConfiguration).mount(
      componentRef.current
    );
    this._setComponent(card);
  }

  _getOnPaymentSuccessCallback(transactionId, sessionId) {
    const callback = async (data, component) => {
      if (this._customerCreditCardId === undefined) {
        const response =
          await this._internalApiAdyenClient.postAdyenSessionResult(
            this._brand,
            this._endDigits,
            transactionId,
            data.resultCode,
            sessionId
          );
        this._customerCreditCardId = response.customer_credit_card_id;
        this._resolveCustomerCreditCardId(this._customerCreditCardId);
        component.setStatus(AdyenComponentResultStatus.SUCCESS);
      }
    };
    return callback;
  }

  _getOnPaymentFailedCallback() {
    async (data, component) => {
      component.setStatus(AdyenComponentResultStatus.ERROR);
    };
  }

  _getOnFieldValidCallback() {
    const getAttributeDefinitionIfValid = (data, attribute) =>
      data.valid ? data[attribute] : undefined;

    const callback = async (data) => {
      switch (data.fieldType) {
        case AdyenCardComponentFieldType.ENCRYPTED_CARD_NUMBER:
          this._endDigits = getAttributeDefinitionIfValid(data, "endDigits");
          break;

        case AdyenCardComponentFieldType.ENCRYPTED_EXPIRY_DATE:
          this._encryptedExpiryDate = getAttributeDefinitionIfValid(
            data,
            "blob"
          );
          break;

        case AdyenCardComponentFieldType.ENCRYPTED_SECURITY_CODE:
          this._encryptedSecurityCode = getAttributeDefinitionIfValid(
            data,
            "blob"
          );
          break;
      }

      const cardDetails = this.allCardDetailsAreDefined()
        ? this.getCardDetails()
        : null;
      this._setCardDetails(cardDetails);
    };
    return callback;
  }

  _getOnBrandCallback() {
    const callback = async (data) => {
      this._brand = data.brand;
    };
    return callback;
  }

  async _getCustomerCreditCardId() {
    if (this._customerCreditCardId === undefined) {
      await this._customerCreditCardIdPromise;
    }
    return this._customerCreditCardId;
  }

  _getRawCardDetails() {
    return {
      brand: this._brand,
      endDigits: this._endDigits,
      expiryDate: this._encryptedExpiryDate,
      securityCode: this._encryptedSecurityCode,
    };
  }

  async setupAdyenComponent(sessionConfigurationData, componentRef) {
    const { checkoutConfiguration, cardConfiguration } =
      await this._createSessionConfigurations(sessionConfigurationData);
    if (!componentRef.current) {
      console.warn(
        "Adyen component is not able to mount when container reference is missing"
      );
      return;
    }
    await this._mountAdyenComponent(
      checkoutConfiguration,
      cardConfiguration,
      componentRef
    );
  }

  getBrand() {
    const result = this._cardBrandRepresentationForAdyenCode[this._brand];
    return result;
  }

  getEndDigits() {
    return this._endDigits;
  }

  getCardDetails() {
    return {
      brand: this.getBrand(),
      last4: this.getEndDigits(),
    };
  }

  allCardDetailsAreDefined() {
    const cardDetailValues = Object.values(this._getRawCardDetails());
    const result = cardDetailValues.every((value) => value !== undefined);
    return result;
  }

  async submitSession() {
    this._component.submit();
    const customerCreditCardId = await this._getCustomerCreditCardId();
    return customerCreditCardId;
  }
}

export default AdyenPaymentSessionController;
