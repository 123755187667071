import React from "react";
import PropTypes from "prop-types";
import useGQL from "../../api_client/UseGQL";
import { formatDate } from "../../modules/Helpers";
import CsvExportModal from "./CsvExportModal";
import { balanceWithOperations } from "api_client/queries/wireToEft";
import { formatBoolean, formattedDollarValue } from "modules/Formatters";
import { appName } from "../../modules/Helpers";

function ExportBatchesModal(props) {
  let gqlHooks = useGQL();
  const modalTitle = "Download Batches CSV";
  const modalDescription = "Download all batches with filters";
  const filename = `${appName}-batches.csv`;
  const headers = [
    { label: "Date", key: "date" },
    { label: "Id", key: "id" },
    { label: "Type", key: "operationType" },
    { label: "Initiated", key: "isActivated" },
    { label: "Number of Transactions", key: "transactionsNumber" },
    { label: "Disbursed Amount", key: "transactionsAmount" },
    { label: "Transactions Fee", key: "transactionsFee" },
    { label: "Batch Fee", key: "batchFee" },
    { label: "Monthly Fee", key: "monthlyFee" },
    { label: "Total", key: "amount" },
    { label: "Balance", key: "balance" },
  ];

  async function getExportData() {
    const params = {
      ...props.filters,
    };
    let output = {
      operationBalances: {
        operation: {
          date: true,
          id: true,
          operationType: true,
          isActivated: true,
          transactionsNumber: true,
          transactionsAmount: true,
          transactionsFee: true,
          batchFee: true,
          monthlyFee: true,
          amount: true,
        },
        balance: true,
      },
    };
    const response = await balanceWithOperations(params, output, gqlHooks);
    if (response) {
      return formatExportData(response.data["operationBalances"]);
    }
  }
  async function getExportDataCount() {
    const params = {
      ...props.filters,
    };
    const response = await balanceWithOperations(
      params,
      { count: true },
      gqlHooks
    );
    if (response) {
      return response["data"]["count"];
    }
  }

  function formatExportData(data) {
    return data.map(({ operation, balance }) => ({
      ...operation,
      balance: formattedDollarValue(balance),
      isActivated: formatBoolean(operation.isActivated),
      date: formatDate(operation.date),
      transactionsAmount: formattedDollarValue(operation.transactionsAmount),
      transactionsFee: formattedDollarValue(operation.transactionsFee),
      monthlyFee: formattedDollarValue(operation.monthlyFee),
      batchFee: formattedDollarValue(operation.batchFee),
      amount: formattedDollarValue(operation.amount),
    }));
  }

  return (
    <CsvExportModal
      {...props}
      getExportData={getExportData}
      getExportDataCount={getExportDataCount}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      filename={filename}
      headers={headers}
    />
  );
}

export default ExportBatchesModal;

ExportBatchesModal.propTypes = {
  filters: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
