import moment from "moment";

export const formattedPhoneNumber = (countryCode, areaCode, number) => {
  return (
    countryCode +
    " (" +
    areaCode +
    ") " +
    number.substring(0, 3) +
    "-" +
    number.slice(-4)
  );
};

export const formatMonthYear = (year, month) => {
  return moment({ year: year, month: month - 1 }).format("MMMM YYYY");
};

export const formatPostalCode = (postalCode) =>
  postalCode?.replace(/-|\s/g, "");

export const formattedDollarValue = (amount) => {
  const formatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  });
  return formatter.format(amount);
};

export const formatBoolean = (value) => {
  if (typeof value !== "boolean") return "N/A";
  return value ? "yes" : "no";
};

export const formatCityAndProvince = (city, province) => {
  if (!city || !province) {
    throw new Error("City and province must be provided.");
  }
  city = city.trim();
  province = province.trim();

  return `${city}, ${province}`;
};

export function trimStringBeforeParentheses(str) {
  const parenthesesIndex = str.indexOf("(");
  return parenthesesIndex === -1 ? str : str.slice(0, parenthesesIndex).trim();
}

export function padSingleDecimalToTwo(amountStr) {
  const trimmedAmount = amountStr.trim();

  const decimalIndex = trimmedAmount.indexOf(".");
  const numDecimals = trimmedAmount.length - decimalIndex - 1;

  if (decimalIndex !== -1 && numDecimals === 1) {
    return trimmedAmount + "0";
  }

  return amountStr;
}
