import { createCustomerCreditCard } from "../../../../api_client/mutations/transaction";
import UnexpectedErrorAlert from "../../../../components/sweet_alert/UnexpectedErrorAlert";
import useGQL, { useErrorHandlerGQL } from "../../../../api_client/UseGQL";
import { useGQLContext } from "../../../../api_client/client";
import { useState } from "react";
import { useSimpleContext } from "../../../../contexts/SimpleContext";

export const useCreditCardHandler = (setErrorMsg) => {
  const [state] = useSimpleContext();
  const [, dispatch] = useGQLContext();
  const defaultErrorHandler = useErrorHandlerGQL(dispatch, false);
  const [alert, setAlert] = useState(null);
  const isRecurring = state.recurringPlan;

  const creditCardErrorHandler = (response) => {
    const fieldErrors =
      response?.data?.createCustomerCreditCard?.fieldErrors?.[0]?.message;
    if (fieldErrors) {
      setErrorMsg(fieldErrors || "Invalid credit card details");
      return false;
    } else if (response?.errors) {
      UnexpectedErrorAlert(() => setAlert(null), setAlert);
    } else {
      return defaultErrorHandler(response);
    }
  };
  const gqlHooks = useGQL(false, creditCardErrorHandler);

  const companyId = isRecurring
    ? state.recurringPlan.company.identifier
    : state.transaction.company.identifier;
  const contactEmail = isRecurring
    ? state.recurringPlan.contact.email
    : state.transaction.contact.email;

  const createStripeCustomer = async (stripeCcToken, cardholderName) => {
    let input = {
      ccToken: stripeCcToken,
      companyId: companyId,
      contactEmail: contactEmail,
      contactName: cardholderName,
      cardholderName: cardholderName,
    };
    let output = {
      customerCreditCard: {
        identifier: true,
        brand: true,
        last4: true,
        stripeCustomerId: true,
      },
    };
    const response = await createCustomerCreditCard(input, output, gqlHooks);
    return response;
  };
  //TODO: abstract with above stripe function
  const createConvergeCustomer = async (
    ccToken,
    brand,
    masked_card_number,
    cardholderName,
    merchantId
  ) => {
    let input = {
      ccToken: ccToken,
      companyId: companyId,
      contactEmail: contactEmail,
      contactName: cardholderName,
      cardholderName: cardholderName,
      merchantId: merchantId,
      brand: brand,
      maskedCardNumber: masked_card_number,
    };
    let output = {
      customerCreditCard: {
        identifier: true,
        brand: true,
        last4: true,
      },
    };
    const response = await createCustomerCreditCard(input, output, gqlHooks);
    return response;
  };
  return { createStripeCustomer, createConvergeCustomer, alert };
};
