import React, { Fragment, useRef, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import PropTypes from "prop-types";
import { withApiValue } from "../../../modules/WithApiValue";
import useExitPrompt from "../../../hooks/useExitPrompt";
import { appName, ENV, environment, personaTemplateKey } from "modules/Helpers";
import Persona from "persona";
import { s } from "react-select/dist/index-fa0d9f94.esm";

const config = {
  email: true,
};

function VerifyMe(props) {
  const { addToast } = useToasts();
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(true);
  const [options, setOptions] = useState(() => {
    const persona_fields = localStorage.getItem("persona_fields");
    if (typeof persona_fields !== "string") {
      return {
        templateId: personaTemplateKey,
      };
    } else {
      const persona_fields_data = JSON.parse(persona_fields);
      return {
        inquiryId: persona_fields_data.inquiryId,
      };
    }
  });

  const [flowType, setFlowType] = useState("embedded");

  const embeddedClientRef = useRef(null);

  const initiatePersona = () => {
    if (embeddedClientRef.current != null) {
      embeddedClientRef.current.open();
    } else {
      const client = new Persona.Client({
        ...options,
        environment: ENV === environment.PRODUCTION ? "production" : "sandbox",
        onLoad: (error) => {
          if (error) {
            // console.error(
            //   `Failed with code: ${error.code} and message ${error.message}`
            // );
            addToast(
              `Please try again. If you are experiencing issues, contact ${appName} support`,
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          }

          client.open();
        },
        onStart: (inquiryId) => {
          console.log(`Started inquiry ${inquiryId}`);
        },
        fields: {
          nameFirst: props.email,
          emailAddress: props.email,
        },
        prefill: {
          nameFirst: props.email,
          emailAddress: props.email,
        },
        onEvent: (name, metadata) => {
          //console.log(`Event ${name} inquiry ${metadata}`);
          if (name === "start") {
            // Collect and save the inquiry ID for future use
            const inquiryId = metadata["inquiryId"];
            localStorage.setItem(
              "persona_fields",
              JSON.stringify({ inquiryId: inquiryId })
            );
          }
        },
        onComplete: onFinish,
      });
      embeddedClientRef.current = client;

      window.exit = (force) =>
        client ? client.exit(force) : alert("Initialize client first");
    }
  };

  useEffect(
    () => () =>
      embeddedClientRef.current != null
        ? embeddedClientRef.current.destroy()
        : console.log("popup already exited"),
    []
  );

  function onFinish(inquiryId) {
    //console.log(`Sending finished inquiry ${inquiryId} to backend`);
    fetch(`/server-handler?inquiry-id=${inquiryId}`);
    let legalName = "";
    let birthDate = "";
    let documentType = "";
    let documentId = "";
    let documentRegion = "";
    if (inquiryId.fields["name-first"]?.value) {
      legalName += inquiryId.fields["name-first"]?.value;
    }
    if (inquiryId.fields["name-middle"]?.value) {
      legalName += inquiryId.fields["name-middle"]?.value;
    }
    if (inquiryId.fields["name-last"]?.value) {
      legalName += inquiryId.fields["name-last"]?.value;
    }
    if (inquiryId.fields["birthdate"]?.value) {
      birthDate = inquiryId.fields["birthdate"]?.value;
    }
    if (inquiryId.fields["current-government-id"]?.type) {
      documentType = inquiryId.fields["current-government-id"]?.type;
    }
    // documentID is currently equal to the identification number
    if (inquiryId.fields["identification-number"]?.value) {
      documentId = inquiryId.fields["identification-number"]?.value;
    }
    // returning the province code of the document
    if (inquiryId.fields["address-subdivision"]?.value) {
      documentRegion = inquiryId.fields["address-subdivision"]?.value;
    }

    localStorage.removeItem("persona_fields");
    props.onComplete(
      inquiryId.inquiryId,
      inquiryId.fields,
      legalName,
      birthDate,
      documentType,
      documentId,
      documentRegion
    );
    //setShowExitPrompt(false);
    //props.onComplete(inquiryId);
  }

  function onError(error) {
    // if (error) {
    //   console.error(
    //     `Failed with code: ${error.code} and message ${error.message}`
    //   );
    // }
    // client.open();
    // setShowExitPrompt(false);
    // if (errorCode === "CANCELLED_BY_USER") {
    //   addToast(
    //     `Please try again. If you are experiencing issues, contact ${appName} support`,
    //     {
    //       appearance: "error",
    //       autoDismiss: true,
    //     }
    //   );
    // } else {
    // addToast(
    //   `Please try again. If you are experiencing issues, contact ${appName} support`,
    //   {
    //     appearance: "error",
    //     autoDismiss: true,
    //   }
    // );
    // }
  }

  return (
    <Fragment>
      {/* <VerifyButton
        apiKey={
          process.env.REACT_APP_PASSBASE
            ? process.env.REACT_APP_PASSBASE
            : "vtPXdQu430RqtVECfPP2iF1U0vV3KhoBmJi6SiCdxZwFDrWN4gZ3N6DIwvwK1ou1"
        }
        onFinish={onFinish}
        onError={onError}
        onStart={() => {
          setShowExitPrompt(true);
        }}
        prefillAttributes={{ email: props.email }}
      /> */}
      <section>
        <button
          onClick={initiatePersona}
          className="btn btn-primary btn-md btn-block"
        >
          Verify Me
        </button>
      </section>
    </Fragment>
  );
}

export default withApiValue(VerifyMe, config);

VerifyMe.propTypes = {
  onComplete: PropTypes.func.isRequired,
  email: PropTypes.string,
};
