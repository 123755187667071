import React from "react";
import PropTypes from "prop-types";
import { CardBrandRepresentationForInternalApiCode } from "modules/constants";

const cardDetailsString = (brand, last4) => {
  const mappedCardBrandString =
    CardBrandRepresentationForInternalApiCode[brand];
  const cardBrandString = mappedCardBrandString ?? brand.capitalize();
  return `${cardBrandString} - ****${last4}`;
};

export const WithdrawalAccount = ({ fromBankAccount }) => {
  return (
    <>
      <h5>Withdrawal account</h5>
      <p>
        {fromBankAccount ? (
          `${fromBankAccount.institution} - ${fromBankAccount.title} - ${fromBankAccount.caRoutingInfo.accountNumber}`
        ) : (
          <span className="text-muted font-italic">Pending authorization</span>
        )}
      </p>
    </>
  );
};

WithdrawalAccount.propTypes = {
  fromBankAccount: PropTypes.object,
};

export const CreditCardAccount = ({ customerCreditCard }) => {
  return (
    <>
      <h5>Credit card</h5>
      <p>
        {customerCreditCard?.brand && customerCreditCard?.last4 ? (
          cardDetailsString(customerCreditCard.brand, customerCreditCard.last4)
        ) : (
          <span className="text-muted font-italic">
            Could not collect credit card details
          </span>
        )}
      </p>
    </>
  );
};

CreditCardAccount.propTypes = {
  customerCreditCard: PropTypes.object,
};
