import React, { Fragment, useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import PaymentDetailsStep from "./steps/PaymentDetailsStep";
import PaymentMethodStep from "./steps/PaymentMethodStep";
import BankTransferMethod from "./methods/BankTransferMethod";
import CreditCardMethod from "./methods/CreditCardMethod";
import PaymentSuccessStep from "./steps/PaymentSuccessStep";
import PaymentTrackStep from "./steps/PaymentTrackStep";
import { useSimpleContext } from "../../contexts/SimpleContext";
import TrustFooter from "../../components/footers/TrustFooter";
import CheckoutSecurityBanner from "../../components/generic_details/CheckoutSecurityBanner";
import PaymentReviewStep from "./steps/PaymentReviewStep";
import { CreditCardsGateway } from "../../gateways/CreditCardsGateway";
import TransactionStates from "../../variables/TransactionStates";
import { BankTransfersGateway } from "../../gateways/BankTransfersGateway";

function PayDebit() {
  const history = useHistory();
  const [state] = useSimpleContext();
  const location = useLocation();

  useEffect(() => {
    if (
      state.transaction.states[0].state !== TransactionStates.REQUESTED &&
      !location.pathname.includes("success")
    ) {
      history.push(
        `/payment/debit/${state.transaction.identifier}/track` + location.search
      );
    }
  }, [location.pathname]);

  useEffect(() => {
    if (state.transaction.states[0].state === TransactionStates.REQUESTED) {
      history.push(
        `/payment/debit/${state.transaction.identifier}/details` +
          location.search
      );
    } else {
      history.push(
        `/payment/debit/${state.transaction.identifier}/track` + location.search
      );
    }
  }, []);

  return (
    <Fragment>
      <div className="card border">
        <Switch>
          <Route
            path="/payment/debit/:identifier/details"
            render={() => <PaymentDetailsStep />}
          />
          <Route
            path="/payment/debit/:identifier/method"
            render={() => <PaymentMethodStep />}
          />
          <Route
            path="/payment/debit/:identifier/bank-transfer"
            render={(props) => (
              <BankTransfersGateway {...props}>
                <BankTransferMethod />
              </BankTransfersGateway>
            )}
          />
          <Route
            path="/payment/debit/:identifier/credit-card"
            render={(props) => (
              <CreditCardsGateway {...props}>
                <CreditCardMethod></CreditCardMethod>
              </CreditCardsGateway>
            )}
          />
          <Route
            path="/payment/debit/:identifier/review"
            render={() => <PaymentReviewStep />}
          />
          <Route
            path="/payment/debit/:identifier/success"
            render={() => <PaymentSuccessStep />}
          />
          <Route
            path="/payment/debit/:identifier/track"
            render={() => <PaymentTrackStep />}
          />
          <Redirect
            from={"/payment/debit/identifier"}
            to={"/payment/debit/:identifier/details"}
          />
        </Switch>
        <CheckoutSecurityBanner />
      </div>
      <TrustFooter />
    </Fragment>
  );
}

export default PayDebit;

PayDebit.propTypes = {};
