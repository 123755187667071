export function floatToMinorUnits(amount) {
  const MINIMUM_UNIT_FACTOR = 100;
  return Math.round(amount * MINIMUM_UNIT_FACTOR);
}

export function paymentMethodListToMap(paymentMethodList) {
  var paymentMethodForMethodType = {};
  for (let paymentMethodObject of paymentMethodList) {
    const type = paymentMethodObject.methodType;
    const vendor = paymentMethodObject.vendor;
    paymentMethodObject.vendor = vendor.toLowerCase();
    paymentMethodForMethodType[type] = paymentMethodObject;
  }
  return paymentMethodForMethodType;
}
