/* eslint-disable react/prop-types */
import { AddCreditModal } from "./AddCreditModal";
import { pluralize, INTERNAL, downloadJsonToFile } from "modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSimpleContext } from "contexts/SimpleContext";
import { calculateTotalAmount } from "../../bulk_approval/ReviewBulkApproval";
import { Button } from "reactstrap";
import AddIcon from "assets/icons/AddIcon";
import { parseCsvXlsxToJson, validateJson } from "modules/csv";
import {
  padSingleDecimalToTwo,
  trimStringBeforeParentheses,
} from "modules/Formatters";
import { useToasts } from "react-toast-notifications";
import ImportIcon from "assets/icons/ImportIcon";
import ReactTable from "components/tables/ReactTable";
import { ContentPlaceHolder } from "components/ContentPlaceHolders";
import IndeterminateCheckbox from "components/tables/IndeterminateCheckbox";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import ContactTagBadge from "components/badge/ContactTagBadge";
import warningAlert from "components/sweet_alert/WarningAlert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  BULK_CREDIT_BASE_PATH,
  getStepNumber,
  STEPS,
} from "../BulkCreditFlows";
import PropTypes from "prop-types";

export const COLUMN_IDS = {
  SELECTION: "selection",
  CONTACT: "contact",
  TO_BANK_ACCOUNT_LABEL: "toBankAccountLabel",
  TAGS: "tags",
  STATEMENT: "statement",
  AMOUNT: "amount",
  ACTION: "action",
  NOTES: "notes",
};

export const BaseBulkCreditDetailsPage = ({
  sampleImportData,
  csvImportFormat,
  jsonToTransactions,
  hiddenColumns = [],
  disableEdit = false,
  disableNewPaymentButton = false,
  // todo: temp. remove and refactor the way we do validation on this page
  disableAmountValidation = false,
}) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [state, setState] = useSimpleContext();
  const [loading, setLoading] = useState(false);
  const isRowSelectionEnabled = !hiddenColumns.includes(COLUMN_IDS.SELECTION);
  const [addCreditModalOpen, setAddCreditModalOpen] = useState(false);
  const [addBankAccountModal, setAddBankAccountModal] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [editingIndex, setEditingIndex] = useState();
  const [transactions, setTransactions] = useState(state?.bulkCredits || []);
  const fileInputRef = useRef(null);
  const many = transactions?.length > 1;
  const [totalAmount, setTotalAmount] = useState(0);
  const [deleteAlert, setDeleteAlert] = useState();
  const defaultSelection =
    state?.selectedTransactions?.reduce((acc, draft) => {
      return { ...acc, [draft.rowId]: true };
    }, {}) || {};

  useEffect(() => {
    let newTotalAmount = 0;
    let transactionsToTotal = [];

    if (isRowSelectionEnabled) {
      transactionsToTotal =
        selectedRows?.map((row) => {
          return {
            amount: row.original.amount,
          };
        }) || [];
    } else {
      transactionsToTotal = transactions;
    }

    newTotalAmount = calculateTotalAmount(transactionsToTotal);
    setTotalAmount(newTotalAmount);
  }, [selectedRows, transactions]);

  const onRemove = (index) => {
    setDeleteAlert(null);
    transactions.splice(index, 1);
    setTransactions([...transactions]);
  };

  const confirmRemove = (row) => {
    warningAlert(
      () => setDeleteAlert(null),
      setDeleteAlert,
      <Button className="btn-warning" onClick={() => onRemove(row.index)}>
        Remove
      </Button>,
      `Are you sure you want to remove this payment totalling ${formattedDollarValue(
        row.original.amount
      )}?`
    );
  };

  const onUpdatePayment = (newTransaction, index) => {
    setEditingIndex(undefined);
    transactions.splice(index, 1, newTransaction);
    setTransactions([...transactions]);
  };

  const addNewPayment = (newTransaction) => {
    newTransaction["id"] = transactions.length + 1;
    setTransactions([newTransaction, ...transactions]);
    setAddCreditModalOpen(false);
  };

  const downloadSampleFile = () => {
    downloadJsonToFile(sampleImportData, "sample", "csv");
  };

  const parseJsonToTransactions = async (jsonList) => {
    let newTransactions = await jsonToTransactions(jsonList);
    return newTransactions.map((transaction, index) => {
      return { ...transaction, id: transactions.length + index + 1 };
    });
  };

  const getSelectedTransactions = () => {
    const selectedTransactions = selectedRows.map((row) => {
      const selectedTransaction = transactions.find(
        (transaction) => transaction.id === row.original.id
      );
      return {
        ...selectedTransaction,
        rowId: row.id,
      };
    });

    return selectedTransactions;
  };

  const submit = () => {
    setState({
      ...state,
      bulkCredits: isRowSelectionEnabled
        ? getSelectedTransactions()
        : transactions,
    });
    const path = BULK_CREDIT_BASE_PATH + STEPS.REVIEW;
    history.push(path, {
      ...history.location.state,
      currStep: getStepNumber(path, state.flow),
    });
  };

  const handleImport = async (e) => {
    setLoading(true);
    e.preventDefault();
    let file = e.target.files[0];
    if (!file) {
      return;
    }

    try {
      const json = await parseCsvXlsxToJson({
        file: file,
        headerParser: trimStringBeforeParentheses,
        colParser: { amount: padSingleDecimalToTwo },
      });

      validateJson(json, csvImportFormat);

      const newTransactions = await parseJsonToTransactions(json);
      setTransactions([...transactions, ...newTransactions]);

      addToast("File Imported", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (errors) {
      if (Array.isArray(errors)) {
        errors.forEach((error) => {
          addToast(error.message, {
            appearance: "error",
            autoDismiss: false,
          });
        });
      } else {
        addToast(errors.message, {
          appearance: "error",
          autoDismiss: false,
        });
      }
    } finally {
      fileInputRef.current.value = "";
      setLoading(false);
    }
  };

  let columns = useMemo(() => {
    let allColumns = [
      {
        id: COLUMN_IDS.SELECTION,
        accessor: "selection",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      {
        id: COLUMN_IDS.CONTACT,
        Header: "Contact",
        accessor: "contact",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <>
            <div
              className="font-weight-bold mt-1"
              style={{ lineHeight: "1.2" }}
            >
              {cell.value && cell.value.name ? cell.value.name : ""}
            </div>
            <div className="text-muted text-sm mb-1">
              {cell.row.original.direction === INTERNAL ? (
                "Internal Transfer"
              ) : (
                <>
                  {cell.value ? cell.value.email : "Pending Payment Acceptance"}
                </>
              )}
            </div>
          </>
        ),
      },
      {
        id: COLUMN_IDS.TO_BANK_ACCOUNT_LABEL,
        Header: "To bank account",
        accessor: "toBankAccountLabel",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <>
            <div
              className="font-weight-bold mt-1"
              style={{ lineHeight: "1.2" }}
            >
              {cell.value && cell.value}
            </div>
          </>
        ),
      },
      {
        id: COLUMN_IDS.TAGS,
        Header: "Tags",
        accessor: "none",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <div>
            {cell.row.original.contact === null ? (
              ""
            ) : (
              <div>
                {ContactTagBadge(
                  cell.row.original.contact?.tags || [],
                  cell.row.original?.identifier
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        id: COLUMN_IDS.STATEMENT,
        Header: "Statement",
        accessor: "statement",
      },
      {
        id: COLUMN_IDS.NOTES,
        Header: "Notes",
        accessor: "note",
      },
      {
        id: COLUMN_IDS.AMOUNT,
        Header: "Amount",
        accessor: "amount",
        style: { th: { textAlign: "right" } },
        // eslint-disable-next-line react/display-name
        Cell: ({ cell }) => (
          <div>
            <div>
              {!disableAmountValidation &&
              (cell.row.original.amount > 600000.0 ||
                cell.row.original.amount < 10.0) ? (
                <>
                  <div
                    className="font-weight-bold"
                    style={{ lineHeight: "1.2" }}
                  >
                    <p style={{ color: "red" }}>
                      ({formattedDollarValue(cell.row.original.amount)})
                    </p>
                  </div>
                  <div className="text-muted text-sm">
                    <p style={{ color: "red" }}>
                      Please enter an amount between $10.00 and $600,000
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="font-weight-bold"
                    style={{ lineHeight: "1.2" }}
                  >
                    <p>({formattedDollarValue(cell.row.original.amount)})</p>
                  </div>
                  <div className="text-muted text-sm">Payable</div>
                </>
              )}
            </div>
          </div>
        ),
      },
      {
        id: COLUMN_IDS.ACTION,
        Header: "Action",
        accessor: "action",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <div className="pull-right">
            {!disableEdit && (
              <Button
                onClick={() => setEditingIndex(cell.row.index)}
                className="p-0 btn-link text-purple-rain"
                size="sm"
              >
                Edit <i className="tim-icons icon-simple-edit" />
              </Button>
            )}
            <Button
              onClick={() => confirmRemove(cell.row)}
              color="danger"
              className="p-0 btn-link"
              size="sm"
            >
              Remove
            </Button>
          </div>
        ),
      },
    ];
    let visibleColumns = allColumns.filter((element) => {
      return !hiddenColumns.includes(element.id);
    });
    return visibleColumns;
  }, [transactions]);

  return (
    <div className="content m-5">
      {addBankAccountModal}
      {deleteAlert}
      {(addCreditModalOpen || editingIndex !== undefined) && (
        <AddCreditModal
          toggle={() => {
            setAddCreditModalOpen(false);
            setEditingIndex(undefined);
          }}
          onSubmit={
            editingIndex !== undefined
              ? (newTr) => onUpdatePayment(newTr, editingIndex)
              : addNewPayment
          }
          initialState={
            editingIndex !== undefined ? transactions[editingIndex] : undefined
          }
          setAddBankAccountModal={setAddBankAccountModal}
        />
      )}
      <div className="d-flex flex-row justify-content-between">
        <h2 className="mb-3 font-weight-600 page-title">
          Add payments{" "}
          <span className="font-weight-500 text-muted text-xl">
            ({transactions?.length || 0})
          </span>
        </h2>
      </div>
      <div className={"d-flex flex-row justify-content-end"}>
        {!disableNewPaymentButton && (
          <Button
            className={"btn-simple btn-primary mr-2"}
            onClick={() => setAddCreditModalOpen(true)}
          >
            <AddIcon /> New payment
          </Button>
        )}
        <div className="fileinput">
          <input type="file" onChange={handleImport} ref={fileInputRef} />
          <Button
            disabled={loading}
            className={"btn-simple btn-primary mr-2"}
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            <ImportIcon height={14} width={14} /> Import
          </Button>
        </div>
        <Button
          disabled={
            isRowSelectionEnabled ? !selectedRows.length : !transactions.length
          }
          onClick={submit}
          type="button"
          className="btn-primary text-black"
        >
          Review {pluralize("payment", many)}
        </Button>
      </div>
      <div className="d-flex flex-row justify-content-end">
        <button
          className={"btn-simple btn-primary mb-3"}
          onClick={downloadSampleFile}
          style={{
            border: "none",
            marginRight: "205px",
            padding: "1px 10px",
            fontSize: "11px",
          }}
        >
          Download sample
        </button>
      </div>
      <div className={"d-flex justify-content-end mb-3"}>
        <b>Total: {formattedDollarValue(totalAmount)}</b>
      </div>
      <ReactTable
        hidePagination={true}
        data={transactions}
        columns={columns}
        selectedRowIds={defaultSelection}
        styles="transaction-table"
        numTd={7}
        onRowSelect={(rows) => setSelectedRows(rows)}
        noContentPlaceholder={
          <ContentPlaceHolder
            titleText={"No payments added"}
            icon={faFileInvoiceDollar}
          />
        }
      />
    </div>
  );
};

BaseBulkCreditDetailsPage.propTypes = {
  sampleImportData: PropTypes.arrayOf(PropTypes.string).isRequired,
  csvImportFormat: PropTypes.objectOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.instanceOf(RegExp), // Accept RegExp objects
        PropTypes.string, // Accept raw regex strings
      ]).isRequired,
      required: PropTypes.bool.isRequired,
    })
  ).isRequired,
  jsonToTransactions: PropTypes.func.isRequired,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  disableEdit: PropTypes.bool,
  disableNewPaymentButton: PropTypes.bool,
};
