import { paymentMethodListToMap } from "./data_conversions";

export function getPaymentMethodFromArray(
  paymentMethodsArray,
  paymentMethodEnum
) {
  const paymentMethodForType = paymentMethodListToMap(paymentMethodsArray);
  const paymentMethodObject = paymentMethodForType[paymentMethodEnum];
  return paymentMethodObject;
}
