import React from "react";
import PropTypes from "prop-types";
import { allTransactions } from "../../api_client/queries/transactions";
import useGQL from "../../api_client/UseGQL";
import { formatDate, getStatus } from "../../modules/Helpers";
import CsvExportModal from "./CsvExportModal";
import { useIsWireToEFTMode } from "hooks/useIsWireToEFTMode";
import { appName } from "../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import { MAX_CSV_EXPORT_PAGE_SIZE } from "modules/csv";

function ExportTransactionsModal(props) {
  let gqlHooks = useGQL();
  const isWireToEFTMode = useIsWireToEFTMode();
  const modalTitle = "Download Payments CSV";
  const modalDescription = "Download all payments with filters";
  const filename = `${appName}-payments.csv`;
  const headers = getHeaders();

  async function getExportData() {
    const params = {
      ...props.filters,
      limit: MAX_CSV_EXPORT_PAGE_SIZE,
    };
    let output = {
      createdAt: true,
      amount: true,
      states: {
        state: true,
        createdAt: true,
      },
      statement: true,
      description: true,
      direction: true,
      contact: {
        email: true,
        name: true,
      },
      estDepositDate: true,
    };

    if (isWireToEFTMode) {
      output["batch"] = { id: true };
    }

    const response = await allTransactions(params, output, gqlHooks);
    if (response) {
      return formatExportData(response.data);
    }
  }

  async function getExportDataCount() {
    const params = {
      ...props.filters,
    };
    const response = await allTransactions(params, {}, gqlHooks);
    if (response) {
      return response["count"];
    }
  }

  function formatExportData(data) {
    return data.map((transaction) => {
      let formattedObj = {
        ...transaction,
        states: getStatus(transaction.states[0].state, transaction.direction)
          .value,
        statesUpdatedAt: formatDate(transaction.states[0].createdAt),
        createdAt: formatDate(transaction.createdAt),
        estDepositDate: transaction.estDepositDate
          ? formatDate(transaction.estDepositDate)
          : "Pending",
        contact: transaction.contact
          ? transaction.contact.name
            ? transaction.contact.name
            : transaction.contact.email
          : "",
        amount: formattedDollarValue(transaction.amount),
      };

      if (isWireToEFTMode && transaction.batch === undefined) {
        throw new Error("Batch information undefined for transaction.");
      }

      if (isWireToEFTMode) {
        formattedObj["batchId"] = formattedObj.batch?.id;
        delete formattedObj["batch"];
      }
      return formattedObj;
    });
  }

  function getHeaders() {
    let headers = [
      { label: "Created At", key: "createdAt" },
      { label: "Amount", key: "amount" },
      { label: "Status", key: "states" },
      { label: "Status Updated At", key: "statesUpdatedAt" },
      { label: "Statement", key: "statement" },
      { label: "Description", key: "description" },
      { label: "Contact", key: "contact" },
      { label: "Estimated Desposit Date", key: "estDepositDate" },
    ];

    if (isWireToEFTMode) {
      headers.push({ label: "Batch ID", key: "batchId" });
    }

    return headers;
  }

  return (
    <CsvExportModal
      {...props}
      getExportData={getExportData}
      getExportDataCount={getExportDataCount}
      headers={headers}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      filename={filename}
      eventName="downloadTransactionsCSV"
    />
  );
}

export default ExportTransactionsModal;

ExportTransactionsModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
};
