import React from "react";
import { Col, Row } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { CustomTooltip, formatDateYear } from "modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MonthlyStatementDetailsSection from "./MonthlyStatementDetailsSection";
import PropTypes from "prop-types";
import { formatMonthYear } from "modules/Formatters";

function MonthlyStatementPrintContent({ monthlyStatementDetails }) {
  const { addToast } = useToasts();
  const copyIdToClipboard = () => {
    addToast("Identifier copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  return (
    <>
      <Row className="mt-3 mb-4">
        <Col md="8" sm="8" className="order-sm-1">
          <div>
            <div className="text-uppercase text-primary mb-2 font-size-xs">
              Statement ID
            </div>
            <h4 className="bd-invoice-id mb-4 font-weight-bold">
              <CustomTooltip title="Click to copy" placement="top" arrow>
                <CopyToClipboard
                  onCopy={() => copyIdToClipboard()}
                  text={monthlyStatementDetails.identifier}
                >
                  <p
                    id={"identifier"}
                    style={{ cursor: "pointer" }}
                    className="cursor-pointer mb-2"
                  >
                    {monthlyStatementDetails.identifier.toUpperCase()}
                  </p>
                </CopyToClipboard>
              </CustomTooltip>
            </h4>
          </div>
        </Col>
      </Row>
      <MonthlyStatementDetailsSection
        title={"Statement Details"}
        details={[
          {
            label: "Statement Period",
            value: formatMonthYear(
              monthlyStatementDetails.year,
              monthlyStatementDetails.month
            ),
          },
          {
            label: "Opening Date",
            value: formatDateYear(monthlyStatementDetails.openingDate),
          },
          {
            label: "Closing Date",
            value: formatDateYear(monthlyStatementDetails.closingDate),
          },
          {
            label: "Opening Balance",
            value: formattedDollarValue(monthlyStatementDetails.openingBalance),
          },
          {
            label: "Closing Balance",
            value: formattedDollarValue(monthlyStatementDetails.closingBalance),
          },
        ]}
      />
      <MonthlyStatementDetailsSection
        title={"Payment Summary"}
        details={[
          {
            label: "Total Disbursed",
            value: formattedDollarValue(
              monthlyStatementDetails.totalDisbursement
            ),
            tooltipTitle: (
              <span>
                Net Amount Disbursed
                <br />
                (After Transaction Fees)
              </span>
            ),
          },
          {
            label: "Total Returned",
            value: formattedDollarValue(monthlyStatementDetails.totalReturn),
            tooltipTitle: "Amount Refunded for Failed Transactions",
          },
          {
            label: "Number of Batches",
            value: monthlyStatementDetails.numberOfBatches,
          },
          {
            label: "Number of Transactions",
            value: monthlyStatementDetails.numberOfTransactions,
          },
          {
            label: "Number of Failed Transactions",
            value: monthlyStatementDetails.numberOfFailedTransactions,
          },
        ]}
      />
      <MonthlyStatementDetailsSection
        title={"Fee Summary"}
        details={[
          {
            label: "Monthly Fee",
            value: formattedDollarValue(monthlyStatementDetails.monthlyFees),
          },
          {
            label: "Total Batch Fees",
            value: formattedDollarValue(monthlyStatementDetails.batchFees),
          },
          {
            label: "Total Failed Transaction Fees",
            value: formattedDollarValue(
              monthlyStatementDetails.failedTransactionFees
            ),
          },
        ]}
      />
    </>
  );
}

export default MonthlyStatementPrintContent;

MonthlyStatementPrintContent.propTypes = {
  monthlyStatementDetails: PropTypes.object.isRequired,
};
