import React, { Fragment, useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import BankTransferMethod from "./methods/BankTransferMethod";
import { useSimpleContext } from "../../contexts/SimpleContext";
import TrustFooter from "../../components/footers/TrustFooter";
import CheckoutSecurityBanner from "../../components/generic_details/CheckoutSecurityBanner";
import RecurringDetailsStep from "./recurring/RecurringDetailsStep";
import AcceptRecurringReviewStep from "./recurring/AcceptRecurringReviewStep";
import RecurringTrackStep from "./recurring/RecurringTrackStep";
import { CustomLoader } from "../../components/loaders/CustomLoader";
import PropTypes from "prop-types";
import PaymentSuccessStep from "./steps/PaymentSuccessStep";
import PaymentMethodStep from "./steps/PaymentMethodStep";
import { CreditCardsGateway } from "../../gateways/CreditCardsGateway";
import CreditCardMethod from "./methods/StripeCreditCardMethod";
import { BankTransfersGateway } from "../../gateways/BankTransfersGateway";

function AcceptRecurring(props) {
  const history = useHistory();
  const [state] = useSimpleContext();
  const location = useLocation();

  useEffect(() => {
    if (state) {
      if (
        !!state.recurringPlan.fromBankAccount &&
        !location.pathname.includes("success")
      ) {
        history.push(
          `/payment/recurring/${state.recurringPlan.identifier}/track`
        );
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (state) {
      if (
        !state.recurringPlan.fromBankAccount &&
        !state.recurringPlan.customerCreditCard
      ) {
        history.push(
          `/payment/recurring/${state.recurringPlan.identifier}/details`
        );
      } else {
        history.push(
          `/payment/recurring/${state.recurringPlan.identifier}/track`
        );
      }
    }
  }, [props.loading]);

  if (props.loading) {
    return <CustomLoader general={true} />;
  }

  return (
    <Fragment>
      <div className="card border">
        <Switch>
          <Route
            path="/payment/recurring/:identifier/details"
            render={() => <RecurringDetailsStep />}
          />
          <Route
            path="/payment/recurring/:identifier/method"
            render={() => <PaymentMethodStep />}
          />
          <Route
            path="/payment/recurring/:identifier/bank-transfer"
            render={(props) => (
              <BankTransfersGateway {...props}>
                <BankTransferMethod />
              </BankTransfersGateway>
            )}
          />
          <Route
            path="/payment/recurring/:identifier/credit-card"
            render={(props) => (
              <CreditCardsGateway {...props}>
                <CreditCardMethod />
              </CreditCardsGateway>
            )}
          />
          <Route
            path="/payment/recurring/:identifier/review"
            render={() => <AcceptRecurringReviewStep />}
          />
          <Route
            path="/payment/recurring/:identifier/success"
            render={() => <PaymentSuccessStep />}
          />
          <Route
            path="/payment/recurring/:identifier/track"
            render={() => <RecurringTrackStep />}
          />
          <Redirect
            from={"/payment/recurring/:identifier"}
            to={"/payment/recurring/:identifier/details"}
          />
        </Switch>
        <CheckoutSecurityBanner />
      </div>
      <TrustFooter />
    </Fragment>
  );
}

export default AcceptRecurring;

AcceptRecurring.propTypes = {
  loading: PropTypes.bool,
};
