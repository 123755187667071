import React, { Fragment, useEffect, useState } from "react";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { useHistory } from "react-router";
import { Button, Input } from "reactstrap";
import { useMixPanelGQL } from "../../../api_client/UseGQL";
import { PulseLoader } from "react-spinners";
import { trackEvent } from "../../../modules/analytics";
import { useCreditCardHandler } from "./hooks/creditCardHandler";
import useStripeMethod from "./StripeMethod";

function StripeCreditCardMethod() {
  const [state, setState] = useSimpleContext();
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const history = useHistory();
  const mixPanelGqlHooks = useMixPanelGQL();
  const { alert } = useCreditCardHandler(setErrorMsg);
  const [name, setName] = useState("");
  const [nameErrorMsg, setNameErrorMsg] = useState("");
  const [customerCreditCard, setCustomerCreditCard] = useState("");
  const {
    getCustomerCreditCard: getCustomerCreditCardStripe,
    CARD_ELEMENT_OPTIONS,
    CardElement,
  } = useStripeMethod(setErrorMsg);
  useEffect(() => {
    if (state.transaction) {
      trackEvent(
        "debitCreditMethod",
        {
          transactionId: state.transaction.identifier,
          companyId: state.transaction.company?.identifier,
          contactId: state.transaction.contact.identifier,
        },
        mixPanelGqlHooks
      );
    } else if (state.recurringPlan) {
      trackEvent(
        "debitCreditMethod",
        {
          transactionId: state.recurringPlan.identifier,
          companyId: state.recurringPlan.company.identifier,
          contactId: state.recurringPlan.contact.identifier,
        },
        mixPanelGqlHooks
      );
    }
  }, []);

  useEffect(() => {
    const isRecurring = state?.recurringPlan;

    if (!customerCreditCard) {
      setLoading(false);
      return;
    }

    if (isRecurring) {
      setState({
        ...state,
        recurringPlan: {
          ...state.recurringPlan,
          customerCreditCard: {
            ...state.recurringPlan?.customerCreditCard,
            ...customerCreditCard,
          },
        },
      });
    } else {
      setState({
        ...state,
        transaction: {
          ...state.transaction,
          customerCreditCard: {
            ...state.transaction?.customerCreditCard,
            ...customerCreditCard,
          },
        },
      });
    }

    history.push({
      pathname: "review",
      search: "?paymentMethod=creditCard",
    });
  }, [customerCreditCard]);

  const handleSubmit = async (event) => {
    setLoading(true);
    setErrorMsg(undefined);
    setNameErrorMsg(undefined);
    event.preventDefault();

    if (!name || name.length < 2) {
      setNameErrorMsg("You must enter the cardholder name.");
      setLoading(false);
      return;
    }

    setLoading(true);
    getCustomerCreditCardStripe(setLoading, setCustomerCreditCard, name);
    return false;
  };

  return (
    <Fragment>
      {alert}
      <form onSubmit={handleSubmit}>
        <div className="pb-4">
          <div
            className={[
              "StripeElement",
              "MgStripeElement",
              nameErrorMsg ? "invalid" : "",
            ].join(" ")}
          >
            <Input
              type="text"
              placeholder="Name as shown on card"
              name="cardholderName"
              value={name}
              onChange={(event) => setName(event.target.value)}
              invalid={!!nameErrorMsg}
              style={{ boxShadow: "none", padding: "8px" }}
            />
          </div>
          {nameErrorMsg && <span className="text-danger">{nameErrorMsg}</span>}

          <CardElement options={CARD_ELEMENT_OPTIONS} />
          {errorMsg && <span className="text-danger">{errorMsg}</span>}
        </div>

        <Button
          disabled={isLoading}
          block
          className="btn-primary btn-lg mt-0 mb-3"
        >
          {isLoading ? <PulseLoader color="white" size={10} /> : "Review & pay"}
        </Button>
      </form>
    </Fragment>
  );
}

export default StripeCreditCardMethod;

StripeCreditCardMethod.propTypes = {};
