import { parse } from "papaparse";
import { emailRex, fileTypes } from "./Helpers";
import { read, utils } from "xlsx";
import { FileReader } from "./FileReader";

// todo: implement pagination. The MAX_CSV_EXPORT_PAGE_SIZE serves as a safe guard
// to prevent excessively large CSV export operations.
export const MAX_CSV_EXPORT_PAGE_SIZE = 1000;

export const payableFormat = {
  // contact fields
  name: {
    value: new RegExp(".+"),
    required: false,
  },
  email: {
    value: emailRex,
    required: true,
  },
  // transaction data
  amount: {
    value: new RegExp("^\\$?\\d+(\\.\\d{2})?$"),
    required: true,
  },
  statement: {
    value: new RegExp(".+"),
    required: true,
  },
  note: {
    value: new RegExp(".+"),
    required: false,
  },
};

export const contactFormat = {
  // contact fields
  name: {
    value: new RegExp("^.{0,64}$"),
    required: true,
  },
  email: {
    value: emailRex,
    required: true,
  },
  tags: {
    value: new RegExp(".+"),
    required: false,
  },
};

export const bankAccountFormat = {
  account_type: {
    value: new RegExp(
      "^(BUSINESS|Business|business|PERSONAL|Personal|personal)$"
    ),
    required: true,
  },
  institution_number: {
    value: new RegExp("^\\d{3}$"),
    required: true,
  },
  transit_number: {
    value: new RegExp("^\\d{5}$"),
    required: true,
  },
  account_number: {
    value: new RegExp("^\\d{7,12}$"),
    required: true,
  },
  institution: {
    value: new RegExp(".+"),
    required: true,
  },
  holder_name: {
    value: new RegExp(".+"),
    required: true,
  },
  holder_email: {
    value: emailRex,
    required: true,
  },
  holder_address: {
    value: new RegExp(".+"),
    required: true,
  },
  holder_address_city: {
    value: new RegExp(".+"),
    required: true,
  },
  holder_address_postal_code: {
    value: new RegExp("^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$"),
    required: true,
  },
};

const isEmpty = (str) => str.length === 0;

export function formatColumnNames(columns) {
  return columns.map(function (columnName) {
    return spaceToCamel(columnName);
  });
}

export function validateJson(json, csvFormat) {
  let errors = [];
  for (const jsonObj of json) {
    for (const formatField in csvFormat) {
      let fieldFound = false;

      for (const field in jsonObj) {
        if (field.includes(camelToSpace(formatField))) {
          fieldFound = true;
          const value = jsonObj[field];

          if (!value && csvFormat[formatField].required) {
            errors.push(
              new Error(`Could not find required field column '${formatField}'`)
            );
          }

          if (value) {
            const error = validateField(formatField, value, csvFormat);
            if (error) {
              errors.push(error);
            }
          }
        }
      }

      if (!fieldFound && csvFormat[formatField].required) {
        errors.push(
          new Error(`Could not find required field column '${formatField}'`)
        );
      }
    }
  }

  if (errors.length !== 0) {
    throw errors;
  }
}

export function validateColumns(columns, csvFormat) {
  columns = formatColumnNames(columns);
  Object.keys(csvFormat).forEach(function (columnName) {
    if (csvFormat[columnName].required && columns.indexOf(columnName) < 0) {
      throw new Error(`Could not find required column '${columnName}'`);
    }
  });
}

export function validateRow(row, columns, csvFormat) {
  columns = formatColumnNames(columns);
  for (const column of columns) {
    const value = row[columns.indexOf(column)];
    const error = validateField(column, value, csvFormat);
    if (error) {
      return error;
    }
  }
}

export function validateField(name, value, csvFormat) {
  if (!isEmpty(value) && !csvFormat[name].value.test(value)) {
    return new Error(
      `'${value}' is not a valid value for field: ${camelToSpace(name)}`
    );
  } else if (isEmpty(value) && csvFormat[name].required) {
    return new Error(`The '${name}' field is required and cannot be empty`);
  }
}

export function getRowContact(row, columns) {
  columns = formatColumnNames(columns);
  return {
    name:
      row[columns.indexOf("name")] ||
      row[columns.indexOf("email")].split("@")[0],
    email: row[columns.indexOf("email")],
    tags: [],
    bankAccounts: [],
  };
}

function spaceToCamel(str) {
  return str.toLowerCase().replace(/\s+(.)/g, function (delimiter) {
    console.log(delimiter);
    return delimiter.trim().toUpperCase();
  });
}

function camelToSpace(string) {
  return string
    .replace(/([A-Z])/g, " $1")
    .trim()
    .toLowerCase();
}

export async function parseCsvXlsxToJson({
  file,
  headerParser = null,
  colParser = {},
} = {}) {
  if (!file) {
    throw new Error("File not found");
  }
  switch (file.type) {
    case fileTypes.CSV:
      return await csvToJson(file, headerParser, colParser);
    case fileTypes.XLSX:
      return await xlsxToJson(file, headerParser, colParser);
    default:
      throw new Error("Unexpected file type: " + file.type);
  }
}

export async function xlsxToJson(file, headerParser = null, colParser = {}) {
  const data = await file.arrayBuffer();
  const workbook = read(data);
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  const csv = utils.sheet_to_csv(worksheet);
  return csvStringToJson(csv, headerParser, colParser);
}

export async function csvToJson(file, headerParser = null, colParser = {}) {
  const fileReader = new FileReader();
  const csvString = await fileReader.readAsText(file);
  return csvStringToJson(csvString, headerParser, colParser);
}

function csvStringToJson(csvString, headerParser = null, colParser = {}) {
  const { data, errors } = parse(csvString);

  if (errors.length > 0) {
    const errorMessages = errors.map((e) => e.message).join(", ");

    throw new Error(`Failed to parse CSV: ${errorMessages}`);
  }

  const headers = data[0];
  const json = data.slice(1).map((row) => {
    let obj = {};
    headers.forEach((rawKey, index) => {
      let key = headerParser ? headerParser(rawKey.trim()) : rawKey.trim();
      let value = row[index]?.trim();

      if (value === undefined || value === null || value === "") {
        return;
      }

      const valueParser = colParser[key] || colParser[rawKey];

      obj[key] = valueParser ? valueParser(value) : value;
    });
    return obj;
  });
  return json.filter((obj) => Object.keys(obj).length > 0);
}
