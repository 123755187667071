import React, { Fragment } from "react";
import BatchesTable from "components/tables/BatchesTable";
import BatchesFilters from "components/modals/BatchesFilters";
import { UncontrolledCollapse } from "reactstrap";
import { useState } from "react";
import FilterIcon from "assets/icons/FilterIcon";
import { Button } from "reactstrap";
import { useEffect } from "react";
import { balanceWithOperations } from "api_client/queries/wireToEft";
import { formattedDollarValue } from "modules/Formatters";
import useGQL from "api_client/UseGQL";
import ExportIcon from "assets/icons/ExportIcon";
import ExportBatchesModal from "components/modals/ExportBatchesModal";

// todo: rename this
function Batches(props) {
  const [loading, setLoading] = useState(true);
  let gqlHooks = useGQL();
  const [filters, setFilters] = useState({
    operationType: undefined,
    dateGte: undefined,
    dateLte: undefined,
  });
  const [filterCount, setFilterCount] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let count = 0;
    if (filters.operationType) {
      count++;
    }
    setFilterCount(count);
  }, [filters]);

  useEffect(async () => {
    let output = {
      currentBalance: true,
    };
    const response = await balanceWithOperations({}, output, gqlHooks);
    if (response) {
      setCurrentBalance(response.data["currentBalance"]);
      setLoading(false);
    }
  }, []);

  const toggleExportBatchesModal = () => {
    setIsOpen(!isOpen);
  };

  if (loading) {
    return null;
  }

  return (
    <Fragment>
      <ExportBatchesModal
        filters={filters}
        toggle={toggleExportBatchesModal}
        isOpen={isOpen}
      />
      <div className="content">
        <div className="d-flex flex-column justify-content-between">
          <div>
            <h2 className="mb-3 font-weight-600 page-title">Batches </h2>
          </div>
          <div className="mb-2 d-flex flex-row justify-content-between align-items-center">
            <div>
              <h4 className="mb-0  d-none d-sm-block">
                Virtual Bank Account Balance:{" "}
                {formattedDollarValue(currentBalance)}
              </h4>
            </div>
            <div className="d-flex flex-row">
              <Button
                className="btn-simple btn-primary sp-button-simple"
                id="buttonToggler"
              >
                <FilterIcon width={15} height={15} strokewidth={3} />{" "}
                <span className="filter-btn-label">Filter</span>
                {filterCount > 0 && (
                  <Fragment>
                    {" "}
                    <span className="text-warning">{filterCount}</span>
                  </Fragment>
                )}
              </Button>
              <div className="desktop">
                <Button
                  className="btn-primary sp-button"
                  onClick={toggleExportBatchesModal}
                >
                  <ExportIcon height={13} width={13} /> <span>Export</span>
                </Button>
              </div>
            </div>
          </div>
          <UncontrolledCollapse toggler="#buttonToggler">
            <BatchesFilters filters={filters} setFilters={setFilters} />
          </UncontrolledCollapse>
        </div>
        <BatchesTable {...props} filters={filters} />
      </div>
    </Fragment>
  );
}

export default Batches;
