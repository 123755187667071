import { ENV, environment } from "./Helpers";
import { cardBrandRepresentationString } from "./constants";

const AdyenEnvironments = { TEST: "test", LIVE: "live" };

const AdyenEnvironmentForHostEnvironment = {
  [environment.LOCAL]: AdyenEnvironments.TEST,
  [environment.DEVELOPMENT]: AdyenEnvironments.TEST,
  [environment.PRODUCTION]: AdyenEnvironments.LIVE,
};

export const AdyenComponentResultStatus = {
  SUCCESS: "success",
  ERROR: "error",
};

export const AdyenComponentDefaults = {
  locale: "en-US",
  enableStoreDetails: true,
  tokenizePaymentMethod: false,
  elementId: "component-container",
  redirectUrl: "https://something.com",
};

const AdyenCardBrandCode = {
  AMEX: "amex",
  ARGENCARD: "argencard",
  BANCONTACT_MISTER_CASH: "bcmc",
  DE_BIJENKORF_CARD: "bijcard",
  CABAL: "cabal",
  CARTE_BANCAIRES: "cartebancaire",
  CODENSA: "codensa",
  CHINA_UNION_PAY: "cup",
  DANKORT: "dankort",
  DINERS_CLUB: "diners",
  DISCOVER: "discover",
  ELECTRON: "electron",
  ELO: "elo",
  FORBRUGSFORENINGEN: "forbrugsforeningen",
  HIPER: "hiper",
  HIPERCARD: "hipercard",
  JCB: "jcb",
  KAREN_MILLEN_GIFTCARD: "karenmillen",
  LASER: "laser",
  MAESTRO: "maestro",
  MAESTRO_UK: "maestrouk",
  MASTERCARD: "mc",
  ALPHA_BANK_MASTERCARD_BONUS: "mcalphabankbonus",
  MIR: "mir",
  NARANJA: "naranja",
  OASIS_GIFTCARD: "oasis",
  RUPAY: "rupay",
  TARJETA_SHOPPING: "shopping",
  SOLO: "solo",
  TROY: "troy",
  UATP: "uatp",
  VISA: "visa",
  ALPHA_BANK_VISA_BONUS: "visaalphabankbonus",
  VISA_DANKORT: "visadankort",
  WAREHOUSE_GIFTCARD: "warehouse",
};

export const CardBrandRepresentationForAdyenCode = {
  [AdyenCardBrandCode.AMEX]: cardBrandRepresentationString.AMEX,
  [AdyenCardBrandCode.ARGENCARD]: cardBrandRepresentationString.ARGENCARD,
  [AdyenCardBrandCode.BANCONTACT_MISTER_CASH]:
    cardBrandRepresentationString.BANCONTACT_MISTER_CASH,
  [AdyenCardBrandCode.DE_BIJENKORF_CARD]:
    cardBrandRepresentationString.DE_BIJENKORF_CARD,
  [AdyenCardBrandCode.CABAL]: cardBrandRepresentationString.CABAL,
  [AdyenCardBrandCode.CARTE_BANCAIRES]:
    cardBrandRepresentationString.CARTE_BANCAIRES,
  [AdyenCardBrandCode.CODENSA]: cardBrandRepresentationString.CODENSA,
  [AdyenCardBrandCode.CHINA_UNION_PAY]:
    cardBrandRepresentationString.CHINA_UNION_PAY,
  [AdyenCardBrandCode.DANKORT]: cardBrandRepresentationString.DANKORT,
  [AdyenCardBrandCode.DINERS_CLUB]: cardBrandRepresentationString.DINERS_CLUB,
  [AdyenCardBrandCode.DISCOVER]: cardBrandRepresentationString.DISCOVER,
  [AdyenCardBrandCode.ELECTRON]: cardBrandRepresentationString.ELECTRON,
  [AdyenCardBrandCode.ELO]: cardBrandRepresentationString.ELO,
  [AdyenCardBrandCode.FORBRUGSFORENINGEN]:
    cardBrandRepresentationString.FORBRUGSFORENINGEN,
  [AdyenCardBrandCode.HIPER]: cardBrandRepresentationString.HIPER,
  [AdyenCardBrandCode.HIPERCARD]: cardBrandRepresentationString.HIPERCARD,
  [AdyenCardBrandCode.JCB]: cardBrandRepresentationString.JCB,
  [AdyenCardBrandCode.KAREN_MILLEN_GIFTCARD]:
    cardBrandRepresentationString.KAREN_MILLEN_GIFTCARD,
  [AdyenCardBrandCode.LASER]: cardBrandRepresentationString.LASER,
  [AdyenCardBrandCode.MAESTRO]: cardBrandRepresentationString.MAESTRO,
  [AdyenCardBrandCode.MAESTRO_UK]: cardBrandRepresentationString.MAESTRO_UK,
  [AdyenCardBrandCode.MASTERCARD]: cardBrandRepresentationString.MASTERCARD,
  [AdyenCardBrandCode.ALPHA_BANK_MASTERCARD_BONUS]:
    cardBrandRepresentationString.ALPHA_BANK_MASTERCARD_BONUS,
  [AdyenCardBrandCode.MIR]: cardBrandRepresentationString.MIR,
  [AdyenCardBrandCode.NARANJA]: cardBrandRepresentationString.NARANJA,
  [AdyenCardBrandCode.OASIS_GIFTCARD]:
    cardBrandRepresentationString.OASIS_GIFTCARD,
  [AdyenCardBrandCode.RUPAY]: cardBrandRepresentationString.RUPAY,
  [AdyenCardBrandCode.TARJETA_SHOPPING]:
    cardBrandRepresentationString.TARJETA_SHOPPING,
  [AdyenCardBrandCode.SOLO]: cardBrandRepresentationString.SOLO,
  [AdyenCardBrandCode.TROY]: cardBrandRepresentationString.TROY,
  [AdyenCardBrandCode.UATP]: cardBrandRepresentationString.UATP,
  [AdyenCardBrandCode.VISA]: cardBrandRepresentationString.VISA,
  [AdyenCardBrandCode.ALPHA_BANK_VISA_BONUS]:
    cardBrandRepresentationString.ALPHA_BANK_VISA_BONUS,
  [AdyenCardBrandCode.VISA_DANKORT]: cardBrandRepresentationString.VISA_DANKORT,
  [AdyenCardBrandCode.WAREHOUSE_GIFTCARD]:
    cardBrandRepresentationString.WAREHOUSE_GIFTCARD,
};

export const AdyenCardComponentFieldType = {
  ENCRYPTED_CARD_NUMBER: "encryptedCardNumber",
  ENCRYPTED_EXPIRY_DATE: "encryptedExpiryDate",
  ENCRYPTED_SECURITY_CODE: "encryptedSecurityCode",
};

export const adyenEnvironment = AdyenEnvironmentForHostEnvironment[ENV];
export const adyenClientKey = process.env.REACT_APP_ADYEN_CLIENT_KEY;
