import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import useGQL from "../../../../api_client/UseGQL";
import { bill } from "../../../../api_client/queries/bills";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { CustomLoader } from "../../../../components/loaders/CustomLoader";
import BillPrintContent from "./BillPrintContent";
import BrandedPrintablePageLayout from "components/layouts/BrandedPrintablePageLayout";
import ThankYouMessage from "components/text/ThankYouMessage";
import { Col, Row } from "reactstrap";
import DetailsCloseButton from "components/buttons/DetailsCloseButton";
import BillTransactionTable from "components/tables/BillTransactionTable";

function Bill(props) {
  const [billDetails, setBillDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const { addToast } = useToasts();
  let history = useHistory();
  let gqlHooks = useGQL();

  useEffect(() => {
    if (props.id) {
      setLoading(true);
      props.setBackdropClick(true);
      const identifier = props.id;
      let input = {
        billId: identifier,
      };
      let output = {
        identifier: true,
        createdAt: true,
        updatedAt: true,
        subtotal: true,
        creditApplied: true,
        tax: true,
        month: true,
        year: true,
        transactions: {
          createdAt: true,
          identifier: true,
          amount: true,
          statement: true,
          description: true,
          padType: true,
          direction: true,
          states: {
            state: true,
            createdAt: true,
          },
        },
        total: true,
        numTransactions: true,
        numDebits: true,
        costDebits: true,
        numCredits: true,
        costCredits: true,
        numInternals: true,
        costInternals: true,
        numNsfs: true,
        costNsfs: true,
        amountTransacted: true,
        owner: {
          email: true,
          company: {
            name: true,
            billingAccount: {
              caRoutingInfo: {
                accountNumber: true,
              },
              institution: true,
              title: true,
              holderAddress: true,
              holderAddressPostalCode: true,
              holderAddressCity: true,
              holderAddressCountry: true,
            },
          },
        },
        payment: {
          amount: true,
          identifier: true,
        },
      };
      bill(input, output, gqlHooks).then((response) => {
        if (response) {
          setBillDetails(response.data);
        }
        setLoading(false);
        props.setBackdropClick(false);
      });
    } else {
      history.push({ search: "" });
      addToast("Could not load bill", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }, []);

  if (loading) {
    return (
      <div className="content">
        <CustomLoader message="Gathering data... thank you for your patience." />
      </div>
    );
  }

  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-end">
        <DetailsCloseButton onClick={props.toggle} disabled={loading} />
      </div>
      <BrandedPrintablePageLayout printEventName={"downloadBill"}>
        <BillPrintContent billDetails={billDetails} />
      </BrandedPrintablePageLayout>
      <h4 className="mt-3">Payments</h4>
      <BillTransactionTable billId={billDetails.identifier} />
      <Row>
        <Col lg="6" sm="12" className="text-center text-md-left mb-3">
          <ThankYouMessage />
        </Col>
      </Row>
    </Fragment>
  );
}

Bill.propTypes = {
  id: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  setBackdropClick: PropTypes.func,
};

export default Bill;
