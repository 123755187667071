import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSimpleContext } from "contexts/SimpleContext";
import { CardBrandRepresentationForAdyenCode } from "modules/adyenConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "@adyen/adyen-web/styles/adyen.css";
import { Button } from "reactstrap";
import AdyenPaymentSessionController from "./adyenPaymentSessionController";
import InternalApiClient from "api_client/rest/internalApiClient";
import InternalApiAdyenClient from "api_client/rest/adyen/internalApiAdyenClient";
import { floatToMinorUnits } from "modules/data_conversions";
import { regionCode, currencyForRegion } from "modules/constants";
import { PulseLoader } from "react-spinners";

function updateContext(
  context,
  setContext,
  customerCreditCard,
  adyenPaymentSessionController
) {
  const updatedCustomerCreditCard = {
    ...context.transaction?.customerCreditCard,
    ...customerCreditCard,
    adyenPaymentSessionController: adyenPaymentSessionController,
  };

  const updatedTransaction = {
    ...context.transaction,
    customerCreditCard: updatedCustomerCreditCard,
  };
  setContext({
    ...context,
    transaction: updatedTransaction,
  });
}

function AdyenCreditCardMethod({
  onCreditCardSuccessPageobject,
  adyenComponentDefaults,
  adyenClientKey,
  adyenEnvironment,
}) {
  const [context, setContext] = useSimpleContext();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [customerCreditCard, setCustomerCreditCard] = useState("");
  const [cardDetails, setCardDetails] = useState(null);
  const [adyenPaymentSessionController] = useState(
    new AdyenPaymentSessionController(
      new InternalApiClient(),
      new InternalApiAdyenClient(),
      CardBrandRepresentationForAdyenCode,
      setCardDetails
    )
  );
  const componentRef = useRef(null);

  //todo: replace with region query on bankAccount from internal API
  const resolveRegion = (bankAccount) => {
    return bankAccount.caRoutingInfo ? regionCode.CA : regionCode.US;
  };

  const region = resolveRegion(context.transaction.toBankAccount);
  const transactionId = context.transaction.identifier;
  const transactionSessionData = {
    transactionId: transactionId,
    redirectUrl: adyenComponentDefaults.redirectUrl,
    tokenizePaymentMethod: adyenComponentDefaults.tokenizePaymentMethod,
    countryCode: region,
  };

  const checkoutConfigurationData = {
    clientKey: adyenClientKey,
    environment: adyenEnvironment,
    amount: {
      value: floatToMinorUnits(context.transaction.amount),
      currency: currencyForRegion[region],
    },
    locale: adyenComponentDefaults.locale,
    countryCode: region,
  };

  const cardConfigurationData = {
    enableStoreDetails: adyenComponentDefaults.enableStoreDetails,
  };

  const sessionConfigurationData = {
    transactionSessionData,
    checkoutConfigurationData,
    cardConfigurationData,
  };

  useEffect(async () => {
    setLoading(true);
    await adyenPaymentSessionController.setupAdyenComponent(
      sessionConfigurationData,
      componentRef
    );
    setLoading(false);
  }, [componentRef]);

  useEffect(() => {
    if (customerCreditCard) {
      updateContext(
        context,
        setContext,
        customerCreditCard,
        adyenPaymentSessionController
      );
      history.push(onCreditCardSuccessPageobject);
    }
  }, [customerCreditCard]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setCustomerCreditCard(cardDetails);
  };

  return (
    <>
      <div ref={componentRef} className="pb-4"></div>
      <Button
        block
        onClick={handleSubmit}
        disabled={!cardDetails}
        className="btn-primary btn-lg mt-0 mb-3"
      >
        {isLoading ? <PulseLoader color="white" size={10} /> : "Review & pay"}
      </Button>
    </>
  );
}

AdyenCreditCardMethod.propTypes = {
  onCreditCardSuccessPageobject: PropTypes.object.isRequired,
  adyenComponentDefaults: PropTypes.object.isRequired,
  adyenClientKey: PropTypes.string.isRequired,
  adyenEnvironment: PropTypes.string.isRequired,
};

export default AdyenCreditCardMethod;
