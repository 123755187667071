import React, { useState, useEffect } from "react";
import { PulseLoader } from "react-spinners";
import { Button, Input } from "reactstrap";
import { host } from "api_client/client";
import { useCreditCardHandler } from "./hooks/creditCardHandler";
import axios from "axios";
import { useScript } from "hooks/useScript";
import { convergeLightboxHostLib } from "modules/Helpers";
import { addPathToUrl } from "../../../api_client/utils";
import { useSimpleContext } from "contexts/SimpleContext";
import { useHistory } from "react-router";

function useConvergeMethod(setErrorMsg) {
  const { createConvergeCustomer } = useCreditCardHandler(setErrorMsg);
  useScript("https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js");
  useScript(convergeLightboxHostLib);

  function getCustomerCreditCard(
    setLoading,
    setCustomerCreditCard,
    name,
    convergeMerchantAccount
  ) {
    var tokenRequest = {};
    try {
      tokenRequest = {
        merchant_id: convergeMerchantAccount.merchantId,
        user_id: convergeMerchantAccount.apiUser.userId,
        pin: convergeMerchantAccount.pin,
      };
    } catch (error) {
      setErrorMsg(
        "There seems to be something wrong with the merchant's account configuration, please reach out to Jetpay support so they can resolve this."
      );
      return;
    }

    const callbacks = {
      onError: (error) => {
        // Show error to your customer.
        setErrorMsg(error);
        setLoading(false);
      },
      onCancelled: () => {
        setLoading(false);
      },
      onDeclined: () => {
        setLoading(false);
      },
      onApproval: async (response) => {
        const ccToken = response?.ssl_token;
        const brand = response?.ssl_card_short_description;
        const masked_card_number = response?.ssl_card_number;
        response = await createConvergeCustomer(
          ccToken,
          brand,
          masked_card_number,
          name,
          convergeMerchantAccount.merchantId
        );
        setCustomerCreditCard(response?.customerCreditCard);
        setLoading(false);
      },
    };
    try {
      axios
        .post(
          addPathToUrl(host, "/converge/converge-lightbox-token"),
          tokenRequest
        )
        .then((response) => {
          let token = response.data.lightbox_session_token;
          window.PayWithConverge.open(
            {
              ssl_txn_auth_token: token, //lightbox token,
            },
            callbacks
          );
        });
    } catch (error) {
      console.error(error);
    }
  }

  return { getCustomerCreditCard };
}

function ConvergeCreditCardMethod() {
  const [state, setState] = useSimpleContext();
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [name, setName] = useState("");
  const [nameErrorMsg, setNameErrorMsg] = useState("");
  const history = useHistory();
  const debitFrequencyObj = state.transaction || state.recurringPlan;
  const convergeMerchantAccount =
    debitFrequencyObj?.toBankAccount?.convergeMerchantAccount;
  const [customerCreditCard, setCustomerCreditCard] = useState("");
  const { getCustomerCreditCard: getCustomerCreditCardConverge } =
    useConvergeMethod(setErrorMsg);

  useEffect(() => {
    if (!customerCreditCard) {
      setLoading(false);
      return;
    }

    const isRecurring = !!state?.recurringPlan;
    if (isRecurring) {
      setState({
        ...state,
        recurringPlan: {
          ...state.recurringPlan,
          customerCreditCard: {
            ...state.recurringPlan?.customerCreditCard,
            ...customerCreditCard,
          },
        },
      });
    } else {
      setState({
        ...state,
        transaction: {
          ...state.transaction,
          customerCreditCard: {
            ...state.transaction?.customerCreditCard,
            ...customerCreditCard,
          },
        },
      });
    }

    history.push({
      pathname: "review",
      search: "?paymentMethod=creditCard",
    });
  }, [customerCreditCard]);

  const handleSubmit = async (event) => {
    setLoading(true);
    setErrorMsg(undefined);
    setNameErrorMsg(undefined);
    event.preventDefault();

    if (!name || name.length < 2) {
      setNameErrorMsg("You must enter the cardholder name.");
      setLoading(false);
      return;
    }

    setLoading(true);
    getCustomerCreditCardConverge(
      setLoading,
      setCustomerCreditCard,
      name,
      convergeMerchantAccount
    );
    return false;
  };

  return (
    <>
      {alert}
      <form onSubmit={handleSubmit}>
        <div className="pb-4">
          <div
            className={[
              "StripeElement",
              "MgStripeElement",
              nameErrorMsg ? "invalid" : "",
            ].join(" ")}
          >
            <Input
              type="text"
              placeholder="Name as shown on card"
              name="cardholderName"
              value={name}
              onChange={(event) => setName(event.target.value)}
              invalid={!!nameErrorMsg}
              style={{ boxShadow: "none", padding: "8px" }}
            />
          </div>
          {nameErrorMsg && <span className="text-danger">{nameErrorMsg}</span>}

          {errorMsg && <span className="text-danger">{errorMsg}</span>}
        </div>

        <Button
          disabled={isLoading}
          block
          className="btn-primary btn-lg mt-0 mb-3"
        >
          {isLoading ? <PulseLoader color="white" size={10} /> : "Review & pay"}
        </Button>
      </form>
    </>
  );
}

export default ConvergeCreditCardMethod;
