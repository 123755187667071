export const Vendor = {
  STRIPE: "stripe",
  CONVERGE: "converge",
  ADYEN: "adyen",
};

const currencyCode = {
  CAD: "cad",
  USD: "usd",
};

export const regionCode = {
  CA: "ca",
  US: "us",
};

export const currencyForRegion = {
  [regionCode.CA]: currencyCode.CAD,
  [regionCode.US]: currencyCode.USD,
};

export const cardBrandRepresentationString = {
  AMEX: "Amex",
  ARGENCARD: "Argencard",
  BANCONTACT_MISTER_CASH: "Bancontact/Mister Cash",
  DE_BIJENKORF_CARD: "de Bijenkorf Card",
  CABAL: "Cabal",
  CARTE_BANCAIRES: "Carte Bancaire",
  CODENSA: "Codensa",
  CHINA_UNION_PAY: "China Union Pay",
  DANKORT: "Dankort",
  DINERS_CLUB: "Diners Club",
  DISCOVER: "Discover",
  ELECTRON: "Electron",
  ELO: "ELO",
  FORBRUGSFORENINGEN: "Forbrugsforeningen",
  HIPER: "HiperCard",
  JCB: "JCB",
  KAREN_MILLEN_GIFTCARD: "Karen Millen GiftCard",
  LASER: "Laser",
  MAESTRO: "Maestro",
  MAESTRO_UK: "Maestro UK",
  MASTERCARD: "Mastercard",
  ALPHA_BANK_MASTERCARD_BONUS: "Alpha Bank Mastercard Bonus",
  MIR: "MIR",
  NARANJA: "Naranja",
  OASIS_GIFTCARD: "Oasis GiftCard",
  RUPAY: "RuPay",
  TARJETA_SHOPPING: "Tarjeta Shopping",
  SOLO: "Solo",
  TROY: "Troy",
  UATP: "UATP",
  VISA: "Visa",
  ALPHA_BANK_VISA_BONUS: "Alpha Bank Visa Bonus",
  VISA_DANKORT: "Visa Dankort",
  WAREHOUSE_GIFTCARD: "Warehouse GiftCard",
};

const InternalApiCardBrandCode = {
  AMEX: "amex",
  ARGENCARD: "argencard",
  BANCONTACT_MISTER_CASH: "bcmc",
  DE_BIJENKORF_CARD: "bijcard",
  CABAL: "cabal",
  CARTE_BANCAIRES: "cartebancaire",
  CODENSA: "codensa",
  CHINA_UNION_PAY: "cup",
  DANKORT: "dankort",
  DINERS_CLUB: "diners",
  DISCOVER: "discover",
  ELECTRON: "electron",
  ELO: "elo",
  FORBRUGSFORENINGEN: "forbrugsforeningen",
  HIPER: "hiper",
  HIPERCARD: "hipercard",
  JCB: "jcb",
  KAREN_MILLEN_GIFTCARD: "karenmillen",
  LASER: "laser",
  MAESTRO: "maestro",
  MAESTRO_UK: "maestrouk",
  MASTERCARD: "mc",
  ALPHA_BANK_MASTERCARD_BONUS: "mcalphabankbonus",
  MIR: "mir",
  NARANJA: "naranja",
  OASIS_GIFTCARD: "oasis",
  RUPAY: "rupay",
  TARJETA_SHOPPING: "shopping",
  SOLO: "solo",
  TROY: "troy",
  UATP: "uatp",
  VISA: "visa",
  ALPHA_BANK_VISA_BONUS: "visaalphabankbonus",
  VISA_DANKORT: "visadankort",
  WAREHOUSE_GIFTCARD: "warehouse",
};

export const InternalApiPaymentMethodCode = {
  CREDIT_CARD: "CREDIT_CARD",
  EFT: "EFT",
  ACH: "ACH",
};

export const CardBrandRepresentationForInternalApiCode = {
  [InternalApiCardBrandCode.AMEX]: cardBrandRepresentationString.AMEX,
  [InternalApiCardBrandCode.ARGENCARD]: cardBrandRepresentationString.ARGENCARD,
  [InternalApiCardBrandCode.BANCONTACT_MISTER_CASH]:
    cardBrandRepresentationString.BANCONTACT_MISTER_CASH,
  [InternalApiCardBrandCode.DE_BIJENKORF_CARD]:
    cardBrandRepresentationString.DE_BIJENKORF_CARD,
  [InternalApiCardBrandCode.CABAL]: cardBrandRepresentationString.CABAL,
  [InternalApiCardBrandCode.CARTE_BANCAIRES]:
    cardBrandRepresentationString.CARTE_BANCAIRES,
  [InternalApiCardBrandCode.CODENSA]: cardBrandRepresentationString.CODENSA,
  [InternalApiCardBrandCode.CHINA_UNION_PAY]:
    cardBrandRepresentationString.CHINA_UNION_PAY,
  [InternalApiCardBrandCode.DANKORT]: cardBrandRepresentationString.DANKORT,
  [InternalApiCardBrandCode.DINERS_CLUB]:
    cardBrandRepresentationString.DINERS_CLUB,
  [InternalApiCardBrandCode.DISCOVER]: cardBrandRepresentationString.DISCOVER,
  [InternalApiCardBrandCode.ELECTRON]: cardBrandRepresentationString.ELECTRON,
  [InternalApiCardBrandCode.ELO]: cardBrandRepresentationString.ELO,
  [InternalApiCardBrandCode.FORBRUGSFORENINGEN]:
    cardBrandRepresentationString.FORBRUGSFORENINGEN,
  [InternalApiCardBrandCode.HIPER]: cardBrandRepresentationString.HIPER,
  [InternalApiCardBrandCode.HIPERCARD]: cardBrandRepresentationString.HIPERCARD,
  [InternalApiCardBrandCode.JCB]: cardBrandRepresentationString.JCB,
  [InternalApiCardBrandCode.KAREN_MILLEN_GIFTCARD]:
    cardBrandRepresentationString.KAREN_MILLEN_GIFTCARD,
  [InternalApiCardBrandCode.LASER]: cardBrandRepresentationString.LASER,
  [InternalApiCardBrandCode.MAESTRO]: cardBrandRepresentationString.MAESTRO,
  [InternalApiCardBrandCode.MAESTRO_UK]:
    cardBrandRepresentationString.MAESTRO_UK,
  [InternalApiCardBrandCode.MASTERCARD]:
    cardBrandRepresentationString.MASTERCARD,
  [InternalApiCardBrandCode.ALPHA_BANK_MASTERCARD_BONUS]:
    cardBrandRepresentationString.ALPHA_BANK_MASTERCARD_BONUS,
  [InternalApiCardBrandCode.MIR]: cardBrandRepresentationString.MIR,
  [InternalApiCardBrandCode.NARANJA]: cardBrandRepresentationString.NARANJA,
  [InternalApiCardBrandCode.OASIS_GIFTCARD]:
    cardBrandRepresentationString.OASIS_GIFTCARD,
  [InternalApiCardBrandCode.RUPAY]: cardBrandRepresentationString.RUPAY,
  [InternalApiCardBrandCode.TARJETA_SHOPPING]:
    cardBrandRepresentationString.TARJETA_SHOPPING,
  [InternalApiCardBrandCode.SOLO]: cardBrandRepresentationString.SOLO,
  [InternalApiCardBrandCode.TROY]: cardBrandRepresentationString.TROY,
  [InternalApiCardBrandCode.UATP]: cardBrandRepresentationString.UATP,
  [InternalApiCardBrandCode.VISA]: cardBrandRepresentationString.VISA,
  [InternalApiCardBrandCode.ALPHA_BANK_VISA_BONUS]:
    cardBrandRepresentationString.ALPHA_BANK_VISA_BONUS,
  [InternalApiCardBrandCode.VISA_DANKORT]:
    cardBrandRepresentationString.VISA_DANKORT,
  [InternalApiCardBrandCode.WAREHOUSE_GIFTCARD]:
    cardBrandRepresentationString.WAREHOUSE_GIFTCARD,
};
