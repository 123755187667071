import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import useGQL from "../../../../api_client/UseGQL";
import { useToasts } from "react-toast-notifications";
import {
  CustomTooltip,
  formatDateYear,
  appName,
} from "../../../../modules/Helpers";
import {
  formatCityAndProvince,
  formattedDollarValue,
} from "modules/Formatters";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { trackEvent } from "../../../../modules/analytics";
import PropTypes from "prop-types";
import { billingAddress } from "variables/CompanyInfo";

function BillPrintContent({ billDetails }) {
  const { addToast } = useToasts();
  let gqlHooks = useGQL();

  const copyIdToClipboard = () => {
    addToast("Identifier copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
    trackEvent("copyBillId", {}, gqlHooks);
  };

  return (
    <Fragment>
      <Row className="mt-3 mb-4">
        <Col md="8" sm="8" className="order-sm-1">
          <div className="text-left text-sm-right">
            <div className="text-uppercase text-primary mb-2 font-size-xs">
              Invoice ID
            </div>
            <h4 className="bd-invoice-id mb-4 font-weight-bold">
              <CustomTooltip title="Click to copy" placement="top" arrow>
                <CopyToClipboard
                  onCopy={() => copyIdToClipboard()}
                  text={billDetails.identifier}
                >
                  <p
                    id={"identifier"}
                    style={{ cursor: "pointer" }}
                    className="cursor-pointer mb-2"
                  >
                    {billDetails.identifier.toUpperCase()}
                  </p>
                </CopyToClipboard>
              </CustomTooltip>
            </h4>
          </div>
        </Col>
        {/* todo: change to new address */}
        <Col md="4" sm="4" className="order-sm-0">
          <div>
            <h5 className="text-primary font-weight-600 mb-2 text-uppercase">
              Billed From
            </h5>
            <p className="mb-1 font-weight-600">{appName}</p>
            <p className="text-muted">{billingAddress.street}</p>
            <p className="text-muted">
              {formatCityAndProvince(
                billingAddress.city,
                billingAddress.province
              )}
            </p>
            <p className="text-muted">{billingAddress.postalCode}</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="7" sm="12">
          <div className="mb-4">
            <h5 className="text-primary font-weight-600 mb-2 text-uppercase">
              Billed To
            </h5>
            <p className="mb-1 font-weight-600">
              {billDetails.owner.company.name}
            </p>
            <p className="text-muted text-capitalize">
              {billDetails.owner.company.billingAccount.holderAddress.toLowerCase()}
            </p>
            <p className="text-muted text-capitalize">
              {billDetails.owner.company.billingAccount.holderAddressCity.toLowerCase()}
              , {billDetails.owner.company.billingAccount.holderAddressCountry}
            </p>
            <p className="text-muted">
              {billDetails.owner.company.billingAccount.holderAddressPostalCode.substr(
                0,
                3
              ) +
                " " +
                billDetails.owner.company.billingAccount.holderAddressPostalCode.substr(
                  3,
                  6
                )}
            </p>
          </div>
        </Col>
        <Col md="5" sm="12">
          <h5 className="text-primary font-weight-600 mb-2 text-uppercase">
            Billing Details
          </h5>
          <div className="flex-row">
            <p className="d-inline-block text-muted mb-2">Billing Period</p>
            <p className="pull-right font-weight-600 mb-2">
              {" "}
              {moment(`${billDetails.year}-${billDetails.month}`).format(
                "MMMM YYYY"
              )}
            </p>
          </div>
          <div className="flex-row">
            <p className="d-inline-block text-muted mb-2">Billing Account</p>
            <p className="pull-right font-weight-600 mb-2">
              {billDetails.owner.company.billingAccount.institution} -{" "}
              {billDetails.owner.company.billingAccount.title} -{" "}
              {
                billDetails.owner.company.billingAccount.caRoutingInfo
                  .accountNumber
              }
            </p>
          </div>
          <div className="flex-row">
            <p className="d-inline-block text-muted mb-2">Invoice Date</p>
            <p className="pull-right font-weight-600 mb-2">
              {formatDateYear(billDetails.createdAt)}
            </p>
          </div>
          <div className="flex-row">
            <p className="d-inline-block text-muted mb-2">Payment Volume</p>
            <p className="pull-right font-weight-600 mb-2">
              {formattedDollarValue(billDetails.amountTransacted)}
            </p>
          </div>
          <div className="flex-row">
            <p className="d-inline-block text-muted mb-2">Total Payments</p>
            <p className="pull-right font-weight-600 mb-2">
              {billDetails.numTransactions}
            </p>
          </div>
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md="5" className="ml-auto">
          <div className="flex-row">
            <h5 className="text-primary font-weight-600 mb-2 text-uppercase">
              Payment Summary
            </h5>
          </div>
          <div className="flex-row">
            <p className="d-inline-block text-muted mb-2">NSFs</p>
            <CustomTooltip
              title={`${
                billDetails.numNsfs
              } NSFs totalling ${formattedDollarValue(billDetails.costNsfs)}`}
              placement="top"
              arrow
            >
              <p
                className="pull-right font-weight-600 mb-2"
                style={{ cursor: "pointer" }}
              >
                {formattedDollarValue(billDetails.costNsfs)}{" "}
                <span className="text-muted font-weight-400">
                  ({billDetails.numNsfs})
                </span>
              </p>
            </CustomTooltip>
          </div>
          <div className="flex-row">
            <p className="d-inline-block text-muted mb-2">Internals</p>
            <CustomTooltip
              title={`${
                billDetails.numInternals
              } internals totalling ${formattedDollarValue(
                billDetails.costInternals
              )}`}
              placement="top"
              arrow
            >
              <p
                className="pull-right font-weight-600 mb-2"
                style={{ cursor: "pointer" }}
              >
                {formattedDollarValue(billDetails.costInternals)}{" "}
                <span className="text-muted font-weight-400">
                  ({billDetails.numInternals})
                </span>
              </p>
            </CustomTooltip>
          </div>
          <div className="flex-row">
            <p className="d-inline-block text-muted mb-2">Payables</p>
            <CustomTooltip
              title={`${
                billDetails.numCredits
              } payables totalling ${formattedDollarValue(
                billDetails.costCredits
              )}`}
              placement="top"
              arrow
            >
              <p
                className="pull-right font-weight-600 mb-2"
                style={{ cursor: "pointer" }}
              >
                {formattedDollarValue(billDetails.costCredits)}{" "}
                <span className="text-muted font-weight-400">
                  ({billDetails.numCredits})
                </span>
              </p>
            </CustomTooltip>
          </div>
          <div className="flex-row">
            <p className="d-inline-block text-muted mb-2">Receivables</p>
            <CustomTooltip
              title={`${
                billDetails.numDebits
              } receivables totalling ${formattedDollarValue(
                billDetails.costDebits
              )}`}
              placement="top"
              arrow
            >
              <p
                className="pull-right font-weight-600 mb-2"
                style={{ cursor: "pointer" }}
              >
                {formattedDollarValue(billDetails.costDebits)}{" "}
                <span className="text-muted font-weight-400">
                  ({billDetails.numDebits})
                </span>
              </p>
            </CustomTooltip>
          </div>
          <div className="flex-row border-top border-background">
            <p className="d-inline-block text-muted mt-2 mb-2">Sub-Total</p>
            <CustomTooltip
              title={`${
                billDetails.numTransactions
              } payments totalling ${formattedDollarValue(
                billDetails.subtotal
              )}`}
              placement="top"
              arrow
            >
              <p
                className="pull-right font-weight-600 mt-2 mb-2"
                style={{ cursor: "pointer" }}
              >
                {formattedDollarValue(billDetails.subtotal)}{" "}
                <span className="text-muted font-weight-400">
                  ({billDetails.numTransactions})
                </span>
              </p>
            </CustomTooltip>
          </div>
          <div className="flex-row">
            <p className="d-inline-block text-muted mb-2">Discount</p>
            <p className="pull-right font-weight-600 text-success mb-2">
              -${billDetails.creditApplied}
            </p>
          </div>
          <div className="flex-row">
            <p className="d-inline-block text-muted mb-2">Tax (5%)</p>
            <p className="pull-right font-weight-600 mb-2">
              ${billDetails.tax}
            </p>
          </div>
          <div className="flex-row mt-1">
            <h4 className="d-inline-block font-weight-600 mb-2">Total</h4>
            <h3 className="pull-right font-weight-600 mb-2">
              {formattedDollarValue(billDetails.total)}
            </h3>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

export default BillPrintContent;

BillPrintContent.propTypes = {
  billDetails: PropTypes.object.isRequired,
};
