import React, { Fragment, useEffect, useState } from "react";
import {
  CustomTooltip,
  getContactBankAccountsSelection,
} from "../../../../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router";
import AddIcon from "assets/icons/AddIcon";
import SweetAlert from "react-bootstrap-sweetalert";
import NewContactBankAccount from "views/admin/contacts/NewContactBankAccount";
import { getContact } from "api_client/queries/contacts";
import useGQL from "api_client/UseGQL";

function CreditDepositStep() {
  const history = useHistory();
  const [state, setState] = useSimpleContext();
  const [alertState, setAlertState] = useState(null);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [newAccountTooltipIsOpen, setNewAccountTooltipIsOpen] = useState(false);
  const [toAccount, setToAccount] = useState({
    value: "",
    label: "Let recipient decide",
  });
  let gqlHooks = useGQL();

  useEffect(() => {
    if (state.contactAccount?.bankAccounts?.length === 1) {
      if (state.contactAccount.bankAccounts[0].state !== "PROCESSING") {
        setToAccount({
          key: state.contactAccount.bankAccounts[0]?.identifier,
          value: state.contactAccount.bankAccounts[0]?.identifier,
          label: `${state.contactAccount.bankAccounts[0]?.title} - ${
            state.contactAccount.bankAccounts[0]?.institution
          } - ${state.contactAccount.bankAccounts[0]?.caRoutingInfo.accountNumber.slice(
            -3
          )}`,
        });
      }
    }
  }, []);

  function toAccountOnChange(value, type) {
    if (type.action === "clear") {
      setToAccount({
        value: "",
        label: "Let recipient decide",
      });
    } else {
      setToAccount(value);
    }
  }

  function addBankAccount() {
    setAlertState(
      <SweetAlert title={""} showConfirm={false} onConfirm={() => {}}>
        <Row style={{ marginTop: -30 }}>
          <Col sm="12">
            <Button close onClick={() => setAlertState(null)} />
          </Col>
        </Row>
        <h2 className="text-center">Add a bank account</h2>
        <NewContactBankAccount
          done={(account) => {
            setAlertState(null);
            updateContactAndSetAccount(account);
          }}
          contact={{ identifier: state.contactAccount.value }}
        />
      </SweetAlert>
    );
  }

  async function updateContactAndSetAccount(account) {
    let output = {
      identifier: true,
      name: true,
      createdAt: true,
      email: true,
      contactType: true,
      tags: true,
      bankAccounts: {
        identifier: true,
        state: true,
        institution: true,
        title: true,
        caRoutingInfo: {
          accountNumber: true,
        },
      },
    };
    let result = await getContact(
      { contactId: state.contactAccount.value },
      output,
      gqlHooks
    );
    let contact = result.data;

    setState({
      ...state,
      contactAccount: contact,
    });
    let queriedAccount = contact.bankAccounts.find(
      (ba) => ba.identifier == account.identifier
    );
    if (queriedAccount) {
      setToAccount(getContactBankAccountsSelection([queriedAccount])[0]);
    }
  }

  return (
    <Fragment>
      {alertState}
      <h2 className="text-center">
        Select your contact&apos;s deposit account
      </h2>
      <span className="text-center">
        You&apos;re paying <b>{formattedDollarValue(state.amount)}</b> to{" "}
        <b>
          {state.contactAccount.name
            ? state.contactAccount.name
            : state.contactAccount.email}
        </b>
      </span>
      <div className="new-payment-inner-content mt-3">
        <Label>Contact bank account</Label>
        <CustomTooltip
          title="Letting the recipient decide will prompt your contact to add a bank account and answer your security question."
          placement="top"
          arrow
          open={tooltipIsOpen}
          onOpen={() => setTooltipIsOpen(true)}
          onClose={() => setTooltipIsOpen(false)}
          leaveTouchDelay={10000}
        >
          <Button
            onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
            variant="contained"
            className="btn-link tooltip-button"
          >
            <i className="fas fa-info-circle" />
          </Button>
        </CustomTooltip>
        <Row className="new-payment-account-wrapper">
          <Col>
            <CustomTooltip
              title="If you already have your contact's bank account routing information, you can enter it here to send funds directly to their account."
              placement="top"
              arrow
              open={newAccountTooltipIsOpen}
              onOpen={() => setNewAccountTooltipIsOpen(true)}
              onClose={() => setNewAccountTooltipIsOpen(false)}
              leaveTouchDelay={10000}
            >
              <Button
                color="links"
                className="btn-link new-payment-new-account-btn"
                onClick={() => addBankAccount()}
              >
                <AddIcon /> New account
              </Button>
            </CustomTooltip>
          </Col>
        </Row>
        <FormGroup>
          <Select
            onChange={(value, type) => toAccountOnChange(value, type)}
            className="react-select default"
            classNamePrefix="react-select"
            name="toBankAccountId"
            value={toAccount}
            options={getContactBankAccountsSelection(
              state.contactAccount.bankAccounts
            )}
            required
          />
        </FormGroup>
        <Button
          onClick={() => {
            history.push("review", {
              ...history.location.state,
              currStep: 5,
            });
            setState({ ...state, toBankAccount: toAccount });
          }}
          block
          color="primary"
          type="button"
        >
          Proceed
        </Button>
      </div>
    </Fragment>
  );
}

export default CreditDepositStep;

CreditDepositStep.propTypes = {};
