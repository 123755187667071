import React from "react";
import PropTypes from "prop-types";
import { allPublicTransactions } from "../../api_client/queries/transactions";
import useGQL from "../../api_client/UseGQL";
import { formatDate, getStatus } from "../../modules/Helpers";
import CsvExportModal from "./CsvExportModal";
import { appName } from "../../modules/Helpers";
import { formattedDollarValue } from "modules/Formatters";
import { MAX_CSV_EXPORT_PAGE_SIZE } from "modules/csv";

function ExportCustomerTransactionsModal(props) {
  let gqlHooks = useGQL();
  const modalTitle = "Download Payments CSV";
  const modalDescription = "Download all payments with filters";
  const filename = `${appName}-payments.csv`;
  const headers = [
    { label: "Created At", key: "createdAt" },
    { label: "Amount", key: "amount" },
    { label: "Status", key: "states" },
    { label: "Status Updated At", key: "statesUpdatedAt" },
    { label: "Statement", key: "statement" },
    { label: "Description", key: "description" },
  ];

  async function getExportData() {
    const params = {
      ...props.filters,
      limit: MAX_CSV_EXPORT_PAGE_SIZE,
    };
    let output = {
      createdAt: true,
      amount: true,
      states: {
        state: true,
        createdAt: true,
      },
      statement: true,
      description: true,
      direction: true,
    };

    const response = await allPublicTransactions(params, output, gqlHooks);
    if (response) {
      return formatExportData(response.data);
    }
  }

  async function getExportDataCount() {
    const params = {
      ...props.filters,
    };
    const response = await allPublicTransactions(params, {}, gqlHooks);
    if (response) {
      return response["count"];
    }
  }

  function formatExportData(data) {
    return data.map((transaction) => {
      let formattedObj = {
        ...transaction,
        states: getStatus(transaction.states[0].state, transaction.direction)
          .value,
        statesUpdatedAt: formatDate(transaction.states[0].createdAt),
        createdAt: formatDate(transaction.createdAt),
        amount: formattedDollarValue(transaction.amount),
      };

      return formattedObj;
    });
  }

  return (
    <CsvExportModal
      {...props}
      getExportData={getExportData}
      getExportDataCount={getExportDataCount}
      headers={headers}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      filename={filename}
    />
  );
}

export default ExportCustomerTransactionsModal;

ExportCustomerTransactionsModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
};
