import React, { useContext, useReducer, useState } from "react";
import Cookies from "js-cookie";
import TokenStateGateway from "../gateways/TokenStateGateway";
import { ENV, environment } from "../modules/Helpers";
import { addPathToUrl } from "./utils";

export const setToken = (token) => {
  let time = 8 / 24;
  const cookies =
    ENV === environment.PRODUCTION || ENV === environment.DEVELOPMENT
      ? { expires: time, secure: true }
      : { expires: 8 };
  Cookies.set("token", token, cookies);
};

export const getToken = () => {
  if (Cookies.get("token")) {
    return Cookies.get("token");
  } else {
    return "";
  }
};

export const deleteToken = () => {
  if (Cookies.get("token")) {
    Cookies.remove("token");
  }
  if (Cookies.get("user")) {
    Cookies.remove("user");
  }
};

export const gqlReducer = (state, action) => {
  if (action.type === "setToken") {
    setToken(action.token);
    return {
      ...state,
      token: action.token,
    };
  } else if (action.type === "logout") {
    deleteToken();
    return { ...state, token: "", fieldErrors: {} };
  } else if (action.type === "setFieldErrors") {
    return {
      ...state,
      fieldErrors: action.fieldErrors,
    };
  } else if (action.type === "refresh") {
    return {
      ...state,
      refresh: action.refresh,
    };
  } else {
    return state;
  }
};

const GQLContext = React.createContext();

export const useGQLContext = () => useContext(GQLContext);

const resolveHost = () => {
  switch (ENV) {
    case environment.PRODUCTION:
      return "https://api.jetpay.baselinepayments.com/";
    case environment.LOCAL:
      return "http://localhost:8000/";
    case environment.GRAPHQL:
      return "https://api.dev2.mazumago.com/";
    case environment.STAGING:
      return "https://api.staging.mazumago.com/";
    case environment.DEMO:
      return "https://api.demo.jetpay.baselinepayments.com/";
    case environment.DEVELOPMENT:
    default:
      return "https://api.dev.jetpay.baselinepayments.com/";
  }
};

export const host = resolveHost();

export const graphQLURL = addPathToUrl(host, "graphql/");

// eslint-disable-next-line react/prop-types
function GQLProvider({ children }) {
  let token = getToken();
  const [alert, setAlert] = useState(null);
  return (
    <GQLContext.Provider
      value={useReducer(gqlReducer, {
        token: token,
        fieldErrors: {},
        setAlert: setAlert,
        refresh: false,
      })}
    >
      <TokenStateGateway>
        {children}
        {alert}
      </TokenStateGateway>
    </GQLContext.Provider>
  );
}

export default GQLProvider;
