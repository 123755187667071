import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ContactsTable from "../../../components/tables/ContactsTable";
import useForm from "../../../hooks/UseForms";
import CustomTableSearch from "../../../components/forms/CustomTableSearch";
import { Button, UncontrolledCollapse } from "reactstrap";
import AddIcon from "../../../assets/icons/AddIcon";
import CreateContactModal from "../../../components/modals/CreateContactModal";
import ContactsFilters from "../../../components/modals/ContactsFilters";
import FilterIcon from "../../../assets/icons/FilterIcon";
import ImportIcon from "../../../assets/icons/ImportIcon";
import {
  CustomTooltip,
  downloadJsonToFile,
  fileTypes,
} from "../../../modules/Helpers";
import {
  formatPostalCode,
  padSingleDecimalToTwo,
  trimStringBeforeParentheses,
} from "modules/Formatters";
import {
  bankAccountFormat,
  contactFormat,
  parseCsvXlsxToJson,
  validateJson,
} from "../../../modules/csv";
import { useToasts } from "react-toast-notifications";
import useGQL from "../../../api_client/UseGQL";
import { createBatchContacts } from "../../../api_client/mutations/contacts";
import { createBankAccountManual } from "../../../api_client/mutations/bankAccount";
import { allContacts } from "../../../api_client/queries/contacts";
import { missingBankBranches } from "../../../api_client/queries/bankAccounts";

function Contacts(props) {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const { addToast } = useToasts();
  const { values } = useForm(null, () => null);
  const [filters, setFilters] = useState({});
  const [createContactModal, setCreateContactModal] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [numContacts, setNumContacts] = useState(0);
  let gqlHooks = useGQL();

  useEffect(() => {
    let count = 0;
    if (filters.contactTag) {
      count++;
    }
    if (filters.archived) {
      count++;
    }
    setFilterCount(count);
  }, [filters]);

  useEffect(() => {
    if (values.search !== undefined) {
      const timeoutId = setTimeout(
        () => setFilters({ ...filters, search: values.search }),
        500
      );
      return () => clearTimeout(timeoutId);
    }
  }, [values.search]);

  const getNumContacts = useCallback((num) => {
    setNumContacts(num);
  });

  const handleDownload = () => {
    const data = [
      [
        '"name (Required, max 64 characters)"',
        "email (Required)",
        "tags",
        "account_type (BUSINESS or PERSONAL)",
        "institution_number (3 digits)",
        "transit_number (5 digits)",
        "account_number (7-12 digits)",
        "institution",
        "holder_name",
        "holder_email",
        "holder_address",
        "holder_address_city",
        "holder_address_postal_code",
      ],
      [
        "Kevin",
        "kevin@mail.com",
        "supplier",
        "BUSINESS",
        "777",
        "77777",
        "7777777",
        "RBC",
        "Kevin",
        "kevin@mail.com",
        "132 100 Ave",
        "Kelowna",
        "v3g 9s8",
      ],
      ["Company", "company@mail.com", "customer"],
      ["Another Company", "acompany@mail.com", '"customer, supplier"'],
      ["Dylan", "dylan@mail.com", '"supplier, customer"'],
      ["Bobby Su", "bobby@mail.com"],
    ];
    downloadJsonToFile(data, "Sample", "csv");
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  async function handleImport(e) {
    try {
      setLoading(true);
      e.preventDefault();
      let file = e.target.files[0];
      if (!file) {
        return;
      }

      const json = await parseCsvXlsxToJson({
        file: file,
        headerParser: trimStringBeforeParentheses,
        colParser: { amount: padSingleDecimalToTwo },
      });

      validateJson(json, contactFormat);
      const bankAccountsJson = json.filter(
        (row) => Object.keys(row).length > 3
      );
      validateJson(bankAccountsJson, bankAccountFormat);

      const newContacts = await jsonToContacts(json);
      const archivedContacts = await allContacts(
        { archived: true },
        { email: true },
        gqlHooks
      );
      const unarchivedContacts = await allContacts(
        { archived: false },
        { email: true },
        gqlHooks
      );
      const currentContacts = archivedContacts.data.concat(
        unarchivedContacts.data
      );

      if (currentContacts) {
        let errors = [];
        const currentContactEmails = currentContacts.map((c) => c.email);
        for (const contact of newContacts) {
          if (currentContactEmails.includes(contact.email)) {
            errors.push(new Error(contact.email + " already exists"));
          }
        }
        if (errors.length !== 0) throw errors;
      }

      const bankBranches = [];
      for (const row of bankAccountsJson) {
        bankBranches.push({
          institutionNumber: row.institution_number,
          transitNumber: row.transit_number,
        });
      }
      const missingBankBranchesResponse = await missingBankBranches(
        { bankBranches: bankBranches },
        { institutionNumber: true, transitNumber: true },
        gqlHooks
      );
      if (missingBankBranchesResponse.data.length !== 0) {
        throw missingBankBranchesResponse.data.map(
          (bankBranch) =>
            new Error(
              "Could not find a matching bank branch for 'institution_number': " +
                bankBranch.institutionNumber +
                " and 'transit_number': " +
                bankBranch.transitNumber
            )
        );
      }

      const response = await createBatchContacts(
        { contactsBatch: newContacts },
        {
          contacts: {
            identifier: true,
            email: true,
          },
        },
        gqlHooks
      );

      if (response && bankAccountsJson.length !== 0) {
        for (const bankAccountJson of bankAccountsJson) {
          const contact = response.contacts.find(
            (c) => c.email === bankAccountJson.email
          );
          if (contact) {
            const values = await jsonToBankAccount(bankAccountJson);
            const data = {
              ...values,
              holderAddressCountry: "CA",
              title: "Chequing",
            };
            data.holderAddressPostalCode = formatPostalCode(
              data.holderAddressPostalCode
            );
            let response = await createBankAccountManual(
              {
                bankAccountCa: data,
                contactId: contact.identifier,
                sendEmails: false,
              },
              {},
              gqlHooks
            );
          }
        }
      }

      addToast("File Imported", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (errors) {
      if (Array.isArray(errors)) {
        errors.forEach((error) => {
          const errorMsg = error.message
            .split(" ")
            .map((word) => {
              if (word.length > 30) {
                return word.substring(0, 26) + "...'";
              } else {
                return word;
              }
            })
            .join(" ");
          addToast(errorMsg, {
            appearance: "error",
            autoDismiss: false,
          });
        });
      } else {
        const errorMsg = errors.message
          .split(" ")
          .map((word) => {
            if (word.length > 30) {
              return word.substring(0, 26) + "...'";
            } else {
              return word;
            }
          })
          .join(" ");
        addToast(errorMsg, {
          appearance: "error",
          autoDismiss: false,
        });
      }
    } finally {
      fileInputRef.current.value = "";
      setLoading(false);
    }
  }

  async function jsonToContacts(json) {
    return json.map((row) => {
      return {
        name: row.name || row.email.split("@")[0],
        email: row.email,
        tags: row.tags ? row.tags.split(", ") : [],
        emailInvite: !row.account_type,
      };
    });
  }

  async function jsonToBankAccount(bankAccountJson) {
    return {
      accountType: bankAccountJson["account_type"],
      institutionNumber: bankAccountJson["institution_number"],
      transitNumber: bankAccountJson["transit_number"],
      accountNumber: bankAccountJson["account_number"],
      institution: bankAccountJson["institution"],
      holderName: bankAccountJson["holder_name"],
      holderEmail: bankAccountJson["holder_email"],
      holderAddress: bankAccountJson["holder_address"],
      holderAddressCity: bankAccountJson["holder_address_city"],
      holderAddressPostalCode: bankAccountJson["holder_address_postal_code"],
    };
  }

  return (
    <Fragment>
      <CreateContactModal
        toggle={() => setCreateContactModal(!createContactModal)}
        isOpen={createContactModal}
      />
      <div className="content">
        <div className="d-flex flex-column justify-content-between">
          <h2 className="mb-3 page-title">
            Contacts{" "}
            <span className="text-warning">
              {filters.archived && "Archived"}
            </span>
            <span className="font-weight-500 text-muted text-xl mr-3">
              ({numContacts})
            </span>
          </h2>
          <div className="d-flex flex-row flex-grow-1 justify-content-end">
            <CustomTableSearch
              disableAmount
              filters={filters}
              setFilters={setFilters}
            />
            <div className="ml-3">
              <Button
                className="btn-simple btn-primary sp-button-simple"
                id="buttonToggler"
              >
                <FilterIcon width={15} height={15} strokewidth={3} />{" "}
                <span className="filter-btn-label">Filter</span>
                {filterCount > 0 && (
                  <Fragment>
                    {" "}
                    <span className="text-warning">{filterCount}</span>
                  </Fragment>
                )}
              </Button>
            </div>
            <div>
              <Button
                className="btn-primary sp-button"
                onClick={() => setCreateContactModal(!createContactModal)}
              >
                <AddIcon height={13} width={13} /> <span>Contact</span>
              </Button>
            </div>
            <div className="fileinput d-flex flex-column">
              <input type="file" onChange={handleImport} ref={fileInputRef} />
              <Button
                disabled={loading}
                className={"btn-primary sp-button"}
                onClick={handleClick}
              >
                <ImportIcon height={14} width={14} /> Import
                <CustomTooltip
                  title="All fields are required to add a bank account with a contact. To have leading zeroes in a
                        field, prepend it with an apostrophe like '003 instead of 003."
                  placement="top"
                  arrow
                  leaveTouchDelay={10000}
                >
                  <Button
                    variant="contained"
                    className="btn-link tooltip-button"
                  >
                    <i className="fas fa-info-circle" />
                  </Button>
                </CustomTooltip>
              </Button>
              <button
                className={"btn-simple btn-primary ml-3 mb-3"}
                onClick={handleDownload}
                style={{
                  border: "none",
                  padding: "1px 5px",
                  fontSize: "11px",
                }}
              >
                Download sample
              </button>
            </div>
          </div>
          <UncontrolledCollapse toggler="#buttonToggler">
            <ContactsFilters filters={filters} setFilters={setFilters} />
          </UncontrolledCollapse>
        </div>
        <ContactsTable
          {...props}
          filters={filters}
          refresh={createContactModal || loading}
          getNumContacts={getNumContacts}
        />
      </div>
    </Fragment>
  );
}

export default Contacts;

Contacts.propTypes = {};
