import React from "react";
import { Card, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import { getBankIcon } from "../../modules/Helpers";
import { useHistory } from "react-router";
import { useIsWireToEFTMode } from "hooks/useIsWireToEFTMode";
import { OVERLAY_TYPES } from "variables/OverlayTypes";

function TransactionPaidToCard(props) {
  const history = useHistory();
  const isWireToEFTMode = useIsWireToEFTMode();
  return (
    <>
      <Row>
        <Col>
          <h4 className="font-weight-600 mt-0 mb-2">
            {props.transaction.direction === "INTERNAL"
              ? "Transfer to"
              : "Paid to"}
          </h4>
        </Col>
      </Row>
      <Card className="details-card shadow-sm border">
        {props.transaction.direction === "DEBIT" ? (
          <>
            <Row className="details-row border-bottom">
              <Col>
                <h5>You</h5>
                <p>{props.transaction.company.name}</p>
              </Col>
            </Row>
            <Row className="details-row">
              <Col>
                <div className="bank-logo">
                  {getBankIcon(props.transaction.toBankAccount.institution) ? (
                    <img
                      src={getBankIcon(
                        props.transaction.toBankAccount.institution
                      )}
                      alt="Bank Logo"
                    />
                  ) : (
                    <FontAwesomeIcon icon={faUniversity} />
                  )}
                </div>
                <h5>Deposit account</h5>
                <p>
                  {props.transaction.toBankAccount ? (
                    `${props.transaction.toBankAccount.institution} - ${props.transaction.toBankAccount.title} - ${props.transaction.toBankAccount.caRoutingInfo.accountNumber}`
                  ) : (
                    <span className="text-muted font-italic">
                      Pending authorization
                    </span>
                  )}
                </p>
              </Col>
            </Row>
          </>
        ) : props.transaction.direction === "CREDIT" ? (
          <>
            <Row className="details-row border-bottom">
              <Col>
                <h5>Contact</h5>
                {props.transaction.contact ? (
                  <p
                    className={
                      !isWireToEFTMode && "text-primary cursor-pointer"
                    }
                    onClick={() => {
                      !isWireToEFTMode &&
                        history.push({
                          search: `overlay=true&type=${OVERLAY_TYPES.CONTACT}&id=${props.transaction.contact.identifier}`,
                        });
                    }}
                  >
                    {props.transaction.contact.name ||
                      props.transaction.contact.email}
                  </p>
                ) : (
                  <p className="text-muted font-italic">
                    Pending payment acceptance
                  </p>
                )}
              </Col>
              <Col sm="6">
                <h5>Delivery method</h5>
                <p>{props.transaction.contact.email}</p>
              </Col>
            </Row>
            <Row className="details-row border-bottom">
              <Col>
                <div className="bank-logo">
                  {props.transaction.toBankAccount ? (
                    <img
                      src={getBankIcon(
                        props.transaction.toBankAccount.institution
                      )}
                      alt="Bank Logo"
                    />
                  ) : (
                    <FontAwesomeIcon icon={faUniversity} />
                  )}
                </div>
                <h5>Deposit account</h5>
                <p>
                  {props.transaction.toBankAccount ? (
                    `${props.transaction.toBankAccount.institution} - ${props.transaction.toBankAccount.title} - ${props.transaction.toBankAccount.caRoutingInfo.accountNumber}`
                  ) : (
                    <span className="text-muted font-italic">
                      Pending authorization
                    </span>
                  )}
                </p>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="details-row">
              <Col>
                <div className="bank-logo">
                  {props.transaction.toBankAccount ? (
                    <img
                      src={getBankIcon(
                        props.transaction.toBankAccount.institution
                      )}
                      alt="Bank Logo"
                    />
                  ) : (
                    <FontAwesomeIcon icon={faUniversity} />
                  )}
                </div>
                <h5>Deposit account</h5>
                <p>
                  {props.transaction.toBankAccount ? (
                    `${props.transaction.toBankAccount.institution} - ${props.transaction.toBankAccount.title} - ${props.transaction.toBankAccount.caRoutingInfo.accountNumber}`
                  ) : (
                    <span className="text-muted font-italic">
                      Pending authorization
                    </span>
                  )}
                </p>
              </Col>
            </Row>
          </>
        )}
      </Card>
    </>
  );
}

export default TransactionPaidToCard;
TransactionPaidToCard.propTypes = {
  transaction: PropTypes.object,
};
